import Crop169Icon from "@mui/icons-material/Crop169";
import Crop32Icon from "@mui/icons-material/Crop32";
import Crop75Icon from "@mui/icons-material/Crop75";
import CropDinIcon from "@mui/icons-material/CropDin";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AiEngineList } from "../../../../context/ImageGeneratorContext";
import { useParamsContext } from "../../../../context/juno/ParamsContext";

const ParamsSDSize = () => {
  const { t } = useTranslation();
  const { width, height, setWidthPartial, setHeightPartial } = useParamsContext();
  const params = new URLSearchParams(window.location.search);
  const ai_engine = (params.get("ai_engine") as AiEngineList) || "";

  const handleAspectRatioChange = (aspect: number) => {
    if (ai_engine === "SD") {
      if (aspect === 169) {
        setWidthPartial("SD", 896);
        setHeightPartial("SD", 512);
      }
      if (aspect === 54) {
        setWidthPartial("SD", 768);
        setHeightPartial("SD", 616);
      }
      if (aspect === 45) {
        setWidthPartial("SD", 616);
        setHeightPartial("SD", 768);
      }
      if (aspect === 32) {
        setWidthPartial("SD", 768);
        setHeightPartial("SD", 512);
      }
      if (aspect === 916) {
        setWidthPartial("SD", 512);
        setHeightPartial("SD", 896);
      }
      if (aspect === 23) {
        setWidthPartial("SD", 512);
        setHeightPartial("SD", 768);
      }
      if (aspect === 11) {
        setWidthPartial("SD", 768);
        setHeightPartial("SD", 768);
      }
    } else if (ai_engine === "SDXL") {
      if (aspect === 169) {
        setWidthPartial("SDXL", 1344);
        setHeightPartial("SDXL", 832);
      }
      if (aspect === 54) {
        setWidthPartial("SDXL", 1280);
        setHeightPartial("SDXL", 1024);
      }
      if (aspect === 45) {
        setWidthPartial("SDXL", 1024);
        setHeightPartial("SDXL", 1280);
      }
      if (aspect === 32) {
        setWidthPartial("SDXL", 1216);
        setHeightPartial("SDXL", 832);
      }
      if (aspect === 916) {
        setWidthPartial("SDXL", 768);
        setHeightPartial("SDXL", 1344);
      }
      if (aspect === 23) {
        setWidthPartial("SDXL", 832);
        setHeightPartial("SDXL", 1216);
      }
      if (aspect === 11) {
        setWidthPartial("SDXL", 1024);
        setHeightPartial("SDXL", 1024);
      }
    }
  };

  const selectedAspectRatio = () => {
    if (ai_engine === "SD") {
      if (width.SD === 768 && height.SD === 768) {
        return 11;
      }
      if (width.SD === 768 && height.SD === 512) {
        return 32;
      }
      if (width.SD === 512 && height.SD === 768) {
        return 23;
      }
      if (width.SD === 768 && height.SD === 616) {
        return 54;
      }
      if (width.SD === 616 && height.SD === 768) {
        return 45;
      }
      if (width.SD === 896 && height.SD === 512) {
        return 169;
      }
      if (width.SD === 512 && height.SD === 896) {
        return 916;
      }
    } else if (ai_engine === "SDXL") {
      if (width.SDXL === 1024 && height.SDXL === 1024) {
        return 11;
      }
      if (width.SDXL === 1216 && height.SDXL === 832) {
        return 32;
      }
      if (width.SDXL === 832 && height.SDXL === 1216) {
        return 23;
      }
      if (width.SDXL === 1280 && height.SDXL === 1024) {
        return 54;
      }
      if (width.SDXL === 1024 && height.SDXL === 1280) {
        return 45;
      }
      if (width.SDXL === 1344 && height.SDXL === 832) {
        return 169;
      }
      if (width.SDXL === 768 && height.SDXL === 1344) {
        return 916;
      }
    }
  };

  useEffect(() => {
    if (ai_engine === "SD") {
      if (width.SD && width.SD > 1080) {
        setWidthPartial("SD", 1080);
      }
      if (height.SD && height.SD > 1080) {
        setHeightPartial("SD", 1080);
      }
    } else if (ai_engine === "SDXL") {
      if (width.SDXL && width.SDXL > 1984) {
        setWidthPartial("SDXL", 1984);
      }
      if (height.SDXL && height.SDXL > 1984) {
        setHeightPartial("SDXL", 1984);
      }
    }
  }, []);

  return (
    <>
      {/* アスペクト比をボタンで選択肢、横幅、縦幅をセット */}
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }} gap={1} mb={1}>
        <Typography variant={"body2"}>{t("juno.input.aspectRatio.title")}</Typography>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", mb: 2 }} gap={1}>
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <IconButton
            onClick={() => handleAspectRatioChange(11)}
            color={selectedAspectRatio() === 11 ? "primary" : "default"}
          >
            <CropDinIcon />
          </IconButton>
          <Typography variant={"caption"} onClick={() => handleAspectRatioChange(11)} sx={{ cursor: "pointer" }}>
            1 : 1
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <IconButton
            onClick={() => handleAspectRatioChange(169)}
            color={selectedAspectRatio() === 169 ? "primary" : "default"}
          >
            <Crop169Icon />
          </IconButton>
          <Typography variant={"caption"} onClick={() => handleAspectRatioChange(169)} sx={{ cursor: "pointer" }}>
            16 : 9
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <IconButton
            onClick={() => handleAspectRatioChange(32)}
            color={selectedAspectRatio() === 32 ? "primary" : "default"}
          >
            <Crop32Icon />
          </IconButton>
          <Typography variant={"caption"} onClick={() => handleAspectRatioChange(32)} sx={{ cursor: "pointer" }}>
            3 : 2
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <IconButton
            onClick={() => handleAspectRatioChange(54)}
            color={selectedAspectRatio() === 54 ? "primary" : "default"}
          >
            <Crop75Icon />
          </IconButton>
          <Typography variant={"caption"} onClick={() => handleAspectRatioChange(54)} sx={{ cursor: "pointer" }}>
            5 : 4
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <IconButton
            onClick={() => handleAspectRatioChange(916)}
            color={selectedAspectRatio() === 916 ? "primary" : "default"}
          >
            <Crop169Icon sx={{ rotate: "90deg" }} />
          </IconButton>
          <Typography variant={"caption"} onClick={() => handleAspectRatioChange(916)} sx={{ cursor: "pointer" }}>
            9 : 16
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <IconButton
            onClick={() => handleAspectRatioChange(23)}
            color={selectedAspectRatio() === 23 ? "primary" : "default"}
          >
            <Crop32Icon sx={{ rotate: "90deg" }} />
          </IconButton>
          <Typography variant={"caption"} onClick={() => handleAspectRatioChange(23)} sx={{ cursor: "pointer" }}>
            2 : 3
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <IconButton
            onClick={() => handleAspectRatioChange(45)}
            color={selectedAspectRatio() === 45 ? "primary" : "default"}
          >
            <Crop75Icon sx={{ rotate: "90deg" }} />
          </IconButton>
          <Typography variant={"caption"} onClick={() => handleAspectRatioChange(45)} sx={{ cursor: "pointer" }}>
            4 : 5
          </Typography>
        </Box>
      </Box>
    </>
  );
};
export default ParamsSDSize;
