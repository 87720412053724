import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import { Fade, Paper, TextField, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import axios from "axios";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CardActionArea from "@mui/material/CardActionArea";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Modal from "@mui/material/Modal";
import { alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Skeleton from "@mui/material/Skeleton";
import { aiEngineColor } from "../../utils/aiEngineColor";
import Meta from "../../components/common/Meta";
import ProLabel from "../../components/common/ProLabel";
import { useJunoMethods } from "../../hooks/useJunoMethods";

export default function ImageTemplates() {
  const { t } = useTranslation();
  const theme = useTheme();
  const [list, setList] = React.useState([]);
  const { handleSetSameParams } = useJunoMethods();
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState({});
  const [addPrompt, setAddPrompt] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const getList = async () => {
    const res = await axios.get("/api/v1/juno/image-templates");
    return res.data;
  };

  useEffect(() => {
    try {
      setLoading(true);
      getList().then((data) => {
        setList(data);
        setLoading(false);
      });
    } catch (e) {
      setLoading(false);
    }
  }, []);

  const handleOpen = (template) => {
    setOpen(true);
    setSelected(template);
  };

  const handleSetParams = (template) => {
    let sample = 1;
    if (template.base === "SD") {
      sample = 4;
    } else if (template.base === "SDXL") {
      sample = 4;
    }
    let prompt = template["prompt"];
    if (addPrompt) {
      prompt += addPrompt;
    }
    if (prompt.slice(-2) === ", ") {
      prompt = prompt.slice(0, -2);
    }

    const params = {
      action: template["action"],
      ai_engine: template["base"],
      model: template["model"],
      lora: template["lora"],
      prompt: prompt,
      negative_prompt: template["negative_prompt"],
      sample: sample,
      step: template["step"],
      width: template["width"],
      height: template["height"],
      guidance_scale: template["guidance_scale"],
      seed: 0,
      style: template["style"],
    };
    handleSetSameParams(params);
  };

  return (
    <>
      <Meta title={t("juno.quick.title")} route={"Studio JUNO by Tenak AI"} />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          m: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
          <FlashOnIcon sx={{ mr: 1 }} color={"secondary"} />
          <Typography
            variant={"subtitle1"}
            component={"h2"}
            sx={{ alignItems: "center", display: "flex" }}
            gutterBottom
          >
            {t("juno.quick.title")}
          </Typography>
        </Box>
        <Typography variant={"body2"} component={"p"} sx={{ mb: 4 }}>
          {t("juno.quick.description")}
        </Typography>
        <Grid
          container
          spacing={2}
          sx={{ justifyContent: "center", display: "flex", flexDirection: "row", width: "100%" }}
        >
          {!loading &&
            list &&
            list.map((item, index) => {
              return (
                <Fade in={true} key={index} timeout={Math.min(index * 100, 2000)}>
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={index}>
                    <Card
                      sx={{
                        maxWidth: 345,
                        bgcolor: theme.palette.mode === "dark" ? "background.paper" : "white",
                        boxShadow: 0,
                      }}
                    >
                      <CardActionArea onClick={() => handleOpen(item)}>
                        <CardMedia
                          image={`/images/image-template/${item["id"]}.png`}
                          sx={{
                            height: 200,
                            position: "relative",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                          }}
                        >
                          {/* グラデーションオーバーレイ */}
                          <Box
                            sx={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              right: 0,
                              bottom: 0,
                              background:
                                "linear-gradient(to bottom, transparent 60%, rgba(0, 0, 0, 0.4) 80%, rgba(0, 0, 0, 0.8) 100%)",
                            }}
                          />
                          {/* PRO */}
                          <Box
                            sx={{
                              position: "absolute",
                              top: 4,
                              left: 4,
                              zIndex: 1,
                            }}
                          >
                            {item["pro"] ? <ProLabel /> : null}
                          </Box>

                          {/* BASE */}
                          <Box
                            sx={{
                              position: "absolute",
                              top: 4,
                              right: 4,
                              px: 0.6,
                              py: 0.2,
                              background: alpha(aiEngineColor(item["base"]), 0.8),
                              borderRadius: 1,
                            }}
                          >
                            <Typography variant={"caption"} sx={{ color: "white" }}>
                              {t(`juno.input.engine.${item["base"]}` as const)}
                            </Typography>
                          </Box>
                          {/* テキスト */}
                          <Typography
                            variant="subtitle1"
                            component="h4"
                            sx={{
                              position: "absolute",
                              bottom: 0,
                              left: 0,
                              px: 2,
                              pb: 1,
                              color: "white",
                              zIndex: 1,
                              whiteSpace: "break-spaces",
                            }}
                          >
                            {t(`juno.quick.label.${item["id"]}.title` as const, "")}
                          </Typography>
                        </CardMedia>

                        <CardContent sx={{ display: "flex", flexDirection: "column", height: 96 }}>
                          {/*説明*/}
                          <Box sx={{}}>
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              sx={{
                                overflow: "hidden",
                                display: "-webkit-box",
                                WebkitLineClamp: 3,
                                WebkitBoxOrient: "vertical",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {t(`juno.quick.label.${item["id"]}.description` as const)}
                            </Typography>
                          </Box>
                          <Box flexGrow={1} />
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                </Fade>
              );
            })}
          {loading &&
            [...Array(24)].map((_, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={index}>
                <Card
                  sx={{
                    maxWidth: 345,
                    bgcolor: theme.palette.mode === "dark" ? "background.paper" : "white",
                    boxShadow: 0,
                  }}
                >
                  <CardActionArea>
                    <Skeleton variant="rectangular" width={345} height={200} />
                    <CardContent sx={{ display: "flex", flexDirection: "column", height: 96 }}>
                      <Box sx={{}}>
                        <Skeleton variant="text" width={"100%"} />
                      </Box>
                      <Box flexGrow={1} />
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
        </Grid>
      </Box>
      {!loading && selected && Object.keys(selected).length > 0 && (
        <Modal open={open} onClose={() => setOpen(false)}>
          <Paper
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "100%",
              maxWidth: 600,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              overflow: "auto",
            }}
          >
            <Box
              sx={{
                p: 2,
                mb: 2,
                borderRadius: 1,
                backgroundColor: theme.palette.background.default,
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Typography id="modal-modal-title" variant="h6" component="h3" sx={{ mb: 1 }}>
                  {t(`juno.quick.label.${selected["id"]}.title`)}
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center", flexDirection: "row", gap: 1 }}>
                  {/* base */}
                  <Typography
                    variant={"caption"}
                    component={"p"}
                    sx={{
                      color: "white",
                      px: 2,
                      py: 0.5,
                      background: alpha(theme.palette.primary.main, 0.8),
                      borderRadius: 1,
                      width: "fit-content",
                    }}
                  >
                    {t(`juno.input.engine.${selected["base"]}`)}
                  </Typography>
                  {selected["model"] && (
                    <Typography
                      variant={"caption"}
                      component={"p"}
                      sx={{
                        color: "white",
                        px: 2,
                        py: 0.5,
                        background: alpha(theme.palette.secondary.main, 0.8),
                        borderRadius: 1,
                        width: "fit-content",
                      }}
                    >
                      {selected["model"]?.name}
                    </Typography>
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  height: 100,
                  width: 100,
                  backgroundImage: `url(/images/image-template/${selected["id"]}.png)`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  borderRadius: 1,
                }}
              />
            </Box>
            <Typography id="modal-modal-description" sx={{ mb: 3 }}>
              {t(`juno.quick.label.${selected["id"]}.description`)}
            </Typography>
            <TextField
              id="prompt"
              label={t("juno.history.input")}
              placeholder={t(`juno.quick.label.${selected["id"]}.placeholder`)}
              variant="outlined"
              fullWidth
              autoFocus
              value={addPrompt}
              multiline
              rows={3}
              onChange={(e) => setAddPrompt(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{ mb: 4 }}
            />
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", gap: 2 }}>
              <Button disableElevation variant={"outlined"} color={"primary"} onClick={() => setOpen(false)}>
                {t("common.close")}
              </Button>
              <Button
                disableElevation
                variant={"contained"}
                color={"primary"}
                onClick={() => {
                  handleSetParams(selected);
                  setOpen(false);
                }}
              >
                {t("juno.quick.set")}
              </Button>
            </Box>
          </Paper>
        </Modal>
      )}
    </>
  );
}
