import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import ImageIcon from "@mui/icons-material/Image";
import InfoIcon from "@mui/icons-material/Info";
import PhotoSizeSelectLargeIcon from "@mui/icons-material/PhotoSizeSelectLarge";
import RepeatIcon from "@mui/icons-material/Repeat";
import { Avatar, Box, CircularProgress, Divider, Grid, Tooltip } from "@mui/material";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CopyButtonOrIcon from "../../../../components/common/CopyButtonOrIcon";
import { useAlert } from "../../../../context/AlertContext";
import { ImageInfo } from "../../../../types/junoTypes";
import { useLoginStatus } from "../../../../context/LoginStatusContext";
import { useCustomNavigate } from "../../../../hooks/useCustomNavigate";
import { getFileDateName, localDate } from "../../../../utils/utils";
import ActionLabels from "./ActionLabels";
import Like from "./Like";
import { useCommonContext } from "../../../../context/juno/CommonContext";
import { useJunoMethods } from "../../../../hooks/useJunoMethods";

type ImageModalProps = {
  images: ImageInfo[];
  activeIndex: number;
  setActiveIndex: (activeIndex: number) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
};

const ImageModalDetails = ({ images, activeIndex, setActiveIndex, open, setOpen }: ImageModalProps) => {
  const { t } = useTranslation();
  const { user } = useLoginStatus();
  const theme = useTheme();
  const { handleSetSameParams, handleDownload, handleImg2Img, handleUpscale } = useJunoMethods();
  const { setRefreshTrigger } = useCommonContext();
  const uiLanguage = Cookies.get("ui_language") || "en";
  const { setAlert } = useAlert();
  const [userInfo, setUserInfo] = useState<any>();
  const navigate = useCustomNavigate();

  useEffect(() => {
    if (open && images[activeIndex]) {
      // アクティブ画像が変更されたら、ユーザー情報を取得する
      getUserInfo(images[activeIndex].uuid).then((userInfo) => setUserInfo(userInfo));
    }
  }, [activeIndex, images, open]);

  const [params, setParams] = useState<any>(images[activeIndex]?.params || {});

  // パラメータが変更されたら、それを更新する
  useEffect(() => {
    setParams(images[activeIndex]?.params);
  }, [images, activeIndex]);

  // パラメータを使う
  const handleUseParamsClick = (params: any) => {
    handleSetSameParams(params);
    setOpen(false);
  };

  const handleImg2ImgClick = (params: any) => {
    handleImg2Img(images[activeIndex].uuid, params);
    setOpen(false);
  };

  const handleUpscaleClick = () => {
    handleUpscale(images[activeIndex].uuid);
    setOpen(false);
  };

  const handleModalClose = () => {
    setOpen(false);
    setActiveIndex(0);
  };

  // ユーザー情報取得
  const getUserInfo = async (imageUuid: string) => {
    try {
      const url = `/api/v1/juno/user-info?uuid=${imageUuid}`;
      const csrftoken = Cookies.get("csrftoken");
      const headers = new Headers({
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken!,
      });
      const response = await fetch(url, {
        method: "GET",
        headers: headers,
      });
      const userInfo = await response.json();
      return userInfo;
    } catch (e) {
      console.error(e);
    }
  };

  const [deleting, setDeleting] = useState<boolean>(false);
  const [downloading, setDownloading] = useState<boolean>(false);

  // 画像単体削除
  const handleDeleteImg = async () => {
    try {
      setDeleting(true);
      const uuid = images[activeIndex].uuid;
      const url = `/api/v1/juno/image/${uuid}`;
      const csrftoken = Cookies.get("csrftoken");
      const headers = new Headers({
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken!,
      });
      const response = await fetch(url, {
        method: "DELETE",
        headers: headers,
      });
      const result = await response.json();
      if (result.success) {
        setOpen(false);
        setActiveIndex(0);
        setRefreshTrigger(new Date().getTime());
        setAlert("success", t("juno.message.delete"));
        setDeleting(false);
      }
    } catch (e) {
      setDeleting(false);
      console.error(e);
    }
  };

  const [imgError, setImgError] = useState<{ [key: string]: boolean }>({});

  const handleDownloadImg = async () => {
    try {
      if (images[activeIndex].r2_image) {
        const url = images[activeIndex].r2_image;
        const a = document.createElement("a");
        a.href = url;
        a.download = "juno-" + getFileDateName() + ".png";

        // 画像をバイナリデータとして取得し、Blobに変換
        fetch(url)
          .then((response) => response.blob())
          .then((blob) => {
            const objectURL = URL.createObjectURL(blob);
            a.href = objectURL;
            a.click();
            URL.revokeObjectURL(objectURL); // メモリ解放
          })
          .catch((error) => {
            console.error("Error downloading the image:", error);
          });

        return;
      }

      setDownloading(true);
      const res = await handleDownload(images[activeIndex].uuid);
      if (res["success"]) {
        setDownloading(false);
      }
    } catch (e) {
      setDownloading(false);
      console.error(e);
    }
  };

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "row", mb: 2, alignItems: "center" }} gap={1}>
        <ActionLabels params={params} onClose={handleModalClose} />
        <Box flexGrow={1} />
        <IconButton onClick={() => handleModalClose()} sx={{ position: "absolute", right: 8, top: 8 }}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>

      {/* info */}
      <Box display={"flex"} flexDirection={"row"}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
          }}
          gap={4}
        >
          <Avatar src={userInfo?.avatar}>
            {userInfo?.username != "unknown_user" ? userInfo?.username.slice(0, 2) : ""}
          </Avatar>
          <Typography variant={"body1"} component={"p"}>
            @{userInfo?.username}
            {userInfo?.username === "unknown_user" && (
              <Tooltip title={"The user who generated this image has deleted his account and is therefore anonymous."}>
                <IconButton size={"small"}>
                  <InfoIcon fontSize={"small"} />
                </IconButton>
              </Tooltip>
            )}
          </Typography>

          <Typography
            variant={"caption"}
            component={"p"}
            sx={{
              border: "1px solid",
              borderRadius: 1,
              px: 1,
              borderColor: images[activeIndex]?.is_public ? theme.palette.success.main : theme.palette.info.main,
              color: images[activeIndex]?.is_public ? theme.palette.success.main : theme.palette.info.main,
              py: 0.2,
            }}
          >
            {images[activeIndex]?.is_public ? t("juno.input.public") : t("juno.input.private")}
          </Typography>

          <Box flexGrow={1} />

          <Typography variant={"body1"} component={"p"}>
            {images[activeIndex] && localDate(images[activeIndex]["created_at"], uiLanguage)}
          </Typography>
          <Like uuid={images[activeIndex]?.uuid} onImage={false} />
        </Box>
      </Box>
      <Divider sx={{ my: 2 }} />

      {/* model */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "calc(100% - 235px)",
          overflowY: "auto",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row", mb: 4, width: "100%" }} gap={2}>
          {params?.["model"] && (
            <Box sx={{ display: "flex", flexDirection: "column", maxWidth: 250, width: "100%" }} gap={2}>
              <Typography variant={"caption"} color={"text.secondary"} component={"p"}>
                {t("juno.input.models")}:
              </Typography>
              <Box>
                <Box
                  sx={{
                    height: 64,
                    position: "relative",
                    overflow: "hidden",
                    borderRadius: 2,
                    width: "100%",
                    cursor: "pointer",
                    background: "linear-gradient(135deg, #7f5a83, #0d324d)",
                    "&:hover img": {
                      transform: "scale(1.05)",
                    },
                    "&:hover": {
                      background: "linear-gradient(135deg, #0d324d, #7f5a83)",
                    },
                  }}
                  onClick={() => {
                    navigate(`/juno?page=1&model=${params?.["model"]["id"]}`);
                    setOpen(false);
                  }}
                >
                  {!imgError[params?.["model"]["id"]] && (
                    <img
                      src={params?.["model"]?.screenShot}
                      alt={params?.["model"]?.name}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        transition: "all 0.2s ease-in-out",
                        filter: "brightness(0.4)",
                      }}
                      onError={() =>
                        setImgError({
                          ...imgError,
                          [params?.["model"]["id"]]: true,
                        })
                      }
                    />
                  )}
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      px: 2,
                    }}
                  >
                    <Typography
                      variant={"body2"}
                      component={"p"}
                      sx={{
                        color: "common.white",
                        display: "-webkit-box",
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textAlign: "center",
                        fontWeight: 600,
                      }}
                    >
                      {params?.["model"].name}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}

          {params?.["lora"] && (
            <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }} gap={2}>
              <Typography variant={"caption"} color={"text.secondary"} component={"p"}>
                {t("juno.input.loras")}:
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "row", gap: 2, width: "100%" }}>
                {Array.isArray(params?.["lora"]) &&
                  params?.["lora"].map((lora, index) => (
                    <Box
                      key={index}
                      sx={{
                        width: "100%",
                        height: 64,
                        position: "relative",
                        overflow: "hidden",
                        borderRadius: 2,
                        maxWidth: 250,
                        cursor: "pointer",
                        background: "linear-gradient(135deg, #7f5a83, #0d324d)",
                        "&:hover img": {
                          transform: "scale(1.05)",
                          transformOrigin: "center center",
                        },
                        "&:hover": {
                          background: "linear-gradient(135deg, #0d324d, #7f5a83)",
                        },
                      }}
                      onClick={() => {
                        navigate(`/juno?page=1&lora=${lora["id"]}`);
                        setOpen(false);
                      }}
                    >
                      {!imgError[lora["id"]] && (
                        <img
                          src={lora.screenShot}
                          alt={lora.name}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            objectPosition: "center",
                            transition: "all 0.2s ease-in-out",
                            filter: "brightness(0.4)",
                          }}
                          onError={() =>
                            setImgError({
                              ...imgError,
                              [lora.id]: true,
                            })
                          }
                        />
                      )}

                      <Box
                        sx={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                          p: 1,
                        }}
                      >
                        <Typography
                          variant={"body2"}
                          component={"p"}
                          sx={{
                            color: "common.white",
                            display: "-webkit-box",
                            WebkitLineClamp: 1,
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            textAlign: "center",
                            fontWeight: 600,
                          }}
                        >
                          {lora.name}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 1,
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            variant={"caption"}
                            component={"p"}
                            sx={{
                              color: "common.white",
                              display: "-webkit-box",
                              WebkitLineClamp: 1,
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              textAlign: "center", // テキストを中央揃え
                            }}
                          >
                            {t("juno.input.loraStrength")}:
                          </Typography>
                          <Typography
                            variant={"caption"}
                            component={"p"}
                            sx={{
                              color: "common.white",
                              fontWeight: 600,
                            }}
                          >
                            {lora.strength}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  ))}
              </Box>
            </Box>
          )}
        </Box>

        {/* プロンプト */}
        <Box sx={{ display: "flex", flexDirection: "column", mb: 2, width: "100%" }} gap={2}>
          {params?.["prompt"] && (
            <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
              <TextField
                label={t("juno.history.prompt")}
                fullWidth
                multiline
                rows={5}
                value={params?.["prompt"]}
                InputProps={{ readOnly: true }}
                InputLabelProps={{ shrink: true }}
              />
            </Box>
          )}

          {/* ネガティブプロンプト */}
          {params?.["negative_prompt"] && (
            <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
              <TextField
                label={t("juno.input.negativePrompt")}
                fullWidth
                multiline
                rows={5}
                value={params?.["negative_prompt"]}
                InputProps={{ readOnly: true }}
                InputLabelProps={{ shrink: true }}
              />
            </Box>
          )}
        </Box>

        {/* 各パラメータ */}
        <Box>
          <Grid container spacing={2}>
            {/* サイズ */}
            {params?.["width"] && params?.["height"] && (
              <Grid item xs={12} md={3}>
                <TextField
                  value={`W: ${params?.["width"]}  x  H: ${params?.["height"]}`}
                  variant={"standard"}
                  label={t("juno.history.size")}
                  InputProps={{ readOnly: true }}
                  InputLabelProps={{ shrink: true }}
                  size={"small"}
                  fullWidth
                />
              </Grid>
            )}
            {/* アスペクト比 */}
            {params?.["aspect_ratio"] && (
              <Grid item xs={12} md={3}>
                <TextField
                  value={params["aspect_ratio"]}
                  variant={"standard"}
                  label={t("juno.input.aspectRatio.title")}
                  InputProps={{ readOnly: true }}
                  InputLabelProps={{ shrink: true }}
                  size={"small"}
                  fullWidth
                />
              </Grid>
            )}

            {/* Upscale */}
            {params?.["action"] === "upscale" && (
              <>
                {/* スケールサイズ*/}
                <Grid item xs={12} md={3}>
                  <TextField
                    value={params?.["factor"]}
                    variant={"standard"}
                    label={t("juno.input.scale")}
                    InputProps={{
                      readOnly: true,
                      startAdornment: (
                        <InputAdornment position="start" color="secondary">
                          x
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{ shrink: true }}
                    size={"small"}
                    fullWidth
                  />
                </Grid>
                {params["creativity"] && (
                  <Grid item xs={12} md={3}>
                    <TextField
                      value={params["creativity"]}
                      variant={"standard"}
                      label={t("juno.input.creativity")}
                      InputProps={{ readOnly: true }}
                      size={"small"}
                      fullWidth
                    />
                  </Grid>
                )}
                {params["detail"] && (
                  <Grid item xs={12} md={3}>
                    <TextField
                      value={params["detail"]}
                      variant={"standard"}
                      label={t("juno.input.detail")}
                      InputProps={{ readOnly: true }}
                      size={"small"}
                      fullWidth
                    />
                  </Grid>
                )}
                {params["resemblance"] && (
                  <Grid item xs={12} md={3}>
                    <TextField
                      value={params["resemblance"]}
                      variant={"standard"}
                      label={t("juno.input.resemblance")}
                      InputProps={{ readOnly: true }}
                      size={"small"}
                      fullWidth
                    />
                  </Grid>
                )}
              </>
            )}

            {/* スタイル */}
            {params?.["style"] && (
              <Grid item xs={12} md={3}>
                <TextField
                  value={params?.["style"]}
                  variant={"standard"}
                  label={t("juno.input.style.title")}
                  fullWidth
                  InputProps={{ readOnly: true }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            )}

            {/* スタイル重さ */}
            {params?.["style_weight"] !== undefined && params?.["style_weight"] !== null && (
              <Grid item xs={12} md={3}>
                <TextField
                  value={params?.["style_weight"]}
                  variant={"standard"}
                  label={t("juno.input.style.weight")}
                  fullWidth
                  InputProps={{ readOnly: true }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            )}

            {/* 画像プロンプトの影響度 */}
            {params?.["image_prompt_strength"] && (
              <Grid item xs={12} md={3}>
                <TextField
                  value={params?.["image_prompt_strength"]}
                  variant={"standard"}
                  label={t("juno.history.ip.strength")}
                  InputProps={{ readOnly: true }}
                  InputLabelProps={{ shrink: true }}
                  size={"small"}
                  fullWidth
                />
              </Grid>
            )}

            {params?.["image_strength"] && (
              <Grid item xs={12} md={3}>
                <TextField
                  value={params?.["image_strength"]}
                  variant={"standard"}
                  label={t("juno.input.imageStrength")}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            )}

            {/*  ステップ数  */}
            {params?.["step"] && (
              <Grid item xs={12} md={3}>
                <TextField
                  value={params?.["step"]}
                  variant={"standard"}
                  label={t("juno.input.step")}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            )}

            {/*  guidanceScale  */}
            {params?.["guidance_scale"] && (
              <Grid item xs={12} md={3}>
                <TextField
                  value={params?.["guidance_scale"]}
                  variant={"standard"}
                  label={t("juno.input.guidanceScale")}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            )}

            {/*  seed  */}
            {params?.["seed"] > 0 && (
              <Grid item xs={12} md={3}>
                <TextField
                  value={params?.["seed"]}
                  variant={"standard"}
                  label={t("juno.input.seedSD")}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" color="secondary">
                        <CopyButtonOrIcon displayType={"icon"} textToCopy={params?.["seed"]} size={"small"} />
                      </InputAdornment>
                    ),
                    readOnly: true,
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
      <Divider sx={{ my: 2 }} />

      <Box sx={{ display: "flex", flexDirection: "row", mt: 2 }}>
        {/* アクション */}
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} md={4}>
            <Button
              disableElevation
              variant={"outlined"}
              fullWidth
              onClick={() => handleUpscaleClick()}
              disabled={params?.["width"] > 3000 || params?.["height"] > 3000 || !!images[activeIndex]?.r2_image}
            >
              <PhotoSizeSelectLargeIcon fontSize="small" sx={{ mr: 1 }} />
              <Typography variant={"button"}>{t("juno.history.upscale")}</Typography>
            </Button>
          </Grid>
          <Grid item xs={12} md={4}>
            <Button
              disableElevation
              variant={"outlined"}
              fullWidth
              onClick={() => handleImg2ImgClick(params)}
              disabled={params?.["action"] === "upscale"}
            >
              <ImageIcon fontSize="small" sx={{ mr: 1 }} />
              <Typography variant={"button"}>{t("juno.history.img2img")}</Typography>
            </Button>
          </Grid>
          <Grid item xs={12} md={4}>
            <Button
              disableElevation
              variant={"outlined"}
              fullWidth
              onClick={() => handleUseParamsClick(params)}
              disabled={params?.["action"] === "upscale"}
            >
              <RepeatIcon fontSize="small" sx={{ mr: 1 }} />
              <Typography variant={"button"}>{t("juno.history.useParams")}</Typography>
            </Button>
          </Grid>
          <Grid item xs={12} md={4}>
            <Button
              disableElevation
              variant={"outlined"}
              onClick={() => handleDownloadImg()}
              startIcon={downloading ? <CircularProgress size={20} /> : <DownloadIcon />}
              disabled={downloading}
              fullWidth
            >
              <Typography variant={"button"}>{t("juno.history.download")}</Typography>
            </Button>
          </Grid>
          {userInfo?.username === user?.username && (
            <Grid item xs={12} md={4}>
              <Button
                disableElevation
                variant={"outlined"}
                disabled={deleting}
                onClick={() => handleDeleteImg()}
                color={"error"}
                startIcon={deleting ? <CircularProgress size={20} /> : <DeleteIcon />}
                fullWidth
              >
                <Typography variant={"button"}>{t("common.delete")}</Typography>
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
};

export default ImageModalDetails;
