// useMethods.ts

import { useParamsContext } from "../context/juno/ParamsContext";
import { useUpscaleContext } from "../context/juno/UpscaleContext";
import { useUploadImageContext } from "../context/juno/UploadImageContext";
import { useCustomNavigate } from "./useCustomNavigate";
import axios from "axios";
import Cookies from "js-cookie";
import { getFileDateName } from "../utils/utils";
import { ImageInfo } from "../types/junoTypes";

export const useJunoMethods = () => {
  const navigate = useCustomNavigate();
  const paramsContext = useParamsContext();
  const upscaleContext = useUpscaleContext();
  const uploadImageContext = useUploadImageContext();

  const handleSetSameParams = (params: any) => {
    const {
      setPrompt,
      setWidthPartial,
      setHeightPartial,
      setStep,
      setGuidanceScale,
      setSeed,
      setNegativePrompt,
      setLoraSD,
      setSample,
      setModelSD,
      setModelSDXL,
      setRevisedPrompt,
      setStyle,
      setStyleWeight,
      setAspectRatio,
    } = paramsContext;

    setPrompt(params.prompt);
    if (params.ai_engine === "SD" || params.ai_engine === "SDXL") {
      if (params.width) setWidthPartial(params.ai_engine, params.width);
      if (params.height) setHeightPartial(params.ai_engine, params.height);
      if (params.step) setStep((prev) => ({ ...prev, [params.ai_engine]: params.step }));
      if (params.guidance_scale) setGuidanceScale((prev) => ({ ...prev, [params.ai_engine]: params.guidance_scale }));
      if (params.seed) setSeed((prev) => ({ ...prev, [params.ai_engine]: params.seed }));
      if (params.negative_prompt) setNegativePrompt(params.negative_prompt);
      if (params.lora) setLoraSD(params.lora);
      if (params.sample) setSample((prev) => ({ ...prev, [params.ai_engine]: params.sample }));
      else setSample((prev) => ({ ...prev, [params.ai_engine]: 1 }));
      if (params.image_strength) uploadImageContext.setImageStrength(params.image_strength);
      if (params.ai_engine === "SD") {
        setModelSD(params.model);
      } else setModelSDXL(params.model);
      setRevisedPrompt(false);
    }
    if (params.ai_engine === "DallE3") {
      setWidthPartial("DallE3", params?.width || 1024);
      setHeightPartial("DallE3", params?.height || 1024);
      setStyle((prev) => ({ ...prev, [params.ai_engine]: params.style }));
      setRevisedPrompt(false);
      setPrompt(params.prompt);
    }
    if (params.ai_engine === "SD3" || params.ai_engine === "SDUltra") {
      setAspectRatio((prev) => ({ ...prev, [params.ai_engine]: params.aspect_ratio }));
      if (params.negative_prompt) setNegativePrompt(params.negative_prompt);
      setSeed((prev) => ({ ...prev, [params.ai_engine]: params.seed }));
    }
    if (params.ai_engine.includes("Flux")) {
      if (params.aspect_ratio) setAspectRatio((prev) => ({ ...prev, [params.ai_engine]: params.aspect_ratio }));
      if (params.seed) setSeed((prev) => ({ ...prev, [params.ai_engine]: params.seed }));
      if (params.step) setStep((prev) => ({ ...prev, [params.ai_engine]: params.step }));
      if (params.style) setStyle((prev) => ({ ...prev, [params.ai_engine]: params.style }));
      if (params.style_weight) setStyleWeight((prev) => ({ ...prev, [params.ai_engine]: params.style_weight }));
      if (params.guidance_scale) setGuidanceScale((prev) => ({ ...prev, [params.ai_engine]: params.guidance_scale }));
    }
    if (params.ai_engine === "Ideogram2" || params.ai_engine === "Ideogram2Turbo") {
      setAspectRatio((prev) => ({ ...prev, [params.ai_engine]: params.aspect_ratio }));
      setSeed((prev) => ({ ...prev, [params.ai_engine]: params.seed }));
      setStyle((prev) => ({ ...prev, [params.ai_engine]: params.style }));
    }
    let aiEngine = params.ai_engine;
    if (aiEngine === "Flux1Pro") aiEngine = "Flux11Pro";

    navigate(`/juno/generator?menu=${params.action}&ai_engine=${aiEngine}`);
  };

  const handleImg2Img = async (image_uuid: string, params: any) => {
    try {
      const { setUuidParent } = upscaleContext;
      const { setImage } = uploadImageContext;
      handleSetSameParams(params);
      setUuidParent(image_uuid);
      const response = await axios.get(`/api/v1/juno/image/${image_uuid}`, { responseType: "blob" });
      const blob = new Blob([response.data], { type: response.data.type });

      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result as string;
        setImage(base64data);
      };
      if (
        params.ai_engine === "SD" ||
        params.ai_engine === "SDXL" ||
        params.ai_engine === "Flux1Dev" ||
        params.ai_engine === "Ideogram2" ||
        params.ai_engine === "Ideogram2Turbo"
      ) {
        navigate(`/juno/generator?menu=img2img&ai_engine=${params.ai_engine}`);
      } else {
        navigate(`/juno/generator?menu=img2img&ai_engine=SD3`);
      }
    } catch (error) {
      console.error("画像の取得に失敗しました:", error);
    }
  };

  const handleUpscale = async (image_uuid: string) => {
    try {
      const { setUuidParent } = upscaleContext;
      const { setImage } = uploadImageContext;
      setUuidParent(image_uuid);
      const response = await axios.get(`/api/v1/juno/image/${image_uuid}`, { responseType: "blob" });
      const blob = new Blob([response.data], { type: response.data.type });

      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result as string;
        setImage(base64data);
      };
      navigate(`/juno/generator?menu=upscale&ai_engine=RealESRGAN`);
    } catch (error) {
      console.error("画像の取得に失敗しました:", error);
    }
  };

  const handleDownload = async (image_uuid: string) => {
    try {
      const response = await axios.get(`/api/v1/juno/image/${image_uuid}`, { responseType: "blob" });
      const blob = new Blob([response.data], { type: response.data.type });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `juno-${getFileDateName()}.png`;
      a.click();
      return { success: true };
    } catch (error) {
      console.error("画像の取得に失敗しました:", error);
      return { success: false };
    }
  };

  const fetchHistory = async (): Promise<[ImageInfo][]> => {
    try {
      const url = "/api/v1/juno/generated-images";
      const csrftoken = Cookies.get("csrftoken");
      const headers = {
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken!,
      };
      const response = await axios.get(url, { headers });
      const res_json: [ImageInfo][] = response.data;
      return res_json;
    } catch (e) {
      console.error(e);
      return [];
    }
  };

  const fetchQueuedImages = async (imageRequestUuid: string, action: string): Promise<ImageInfo[]> => {
    try {
      const url = `/api/v1/juno/queued-images?uuid=${imageRequestUuid}&action=${action}`;
      const csrftoken = Cookies.get("csrftoken");

      const headers = {
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken!,
      };

      const response = await axios.get(url, { headers });
      const res_json: ImageInfo[] = response.data;
      return res_json;
    } catch (e) {
      console.error(e);
      return [];
    }
  };

  return {
    handleSetSameParams,
    handleImg2Img,
    handleUpscale,
    handleDownload,
    fetchHistory,
    fetchQueuedImages,
  };
};
