import {
  ArrowBackIosNew as ArrowBackIosNewIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
  Report as ReportIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from "@mui/icons-material";
import { Box, Fade, Grid, IconButton, Modal, Paper, Skeleton, Tooltip, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import InnerImageZoom from "react-inner-image-zoom";
import { ImageInfo } from "../../../../types/junoTypes";
import "../../../../styles/react-inner-zoom.scss";
import ImageModalDetails from "./ImageModalDetails";
import { useCommonContext } from "../../../../context/juno/CommonContext";

type ImageModalProps = {
  images: ImageInfo[];
  activeIndex: number;
  setActiveIndex: (activeIndex: number) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
};

const ImageModal = ({ images, activeIndex, setActiveIndex, open, setOpen }: ImageModalProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const containerRef = useRef<HTMLDivElement>(null);
  const imageContainerRef = useRef<HTMLDivElement>(null);
  const [imageLoaded, setImageLoaded] = useState<boolean[]>(new Array(images.length).fill(false));
  const [imageSize, setImageSize] = useState<{ width: number; height: number }>({ width: 0, height: 0 });
  const { filtered, setFiltered } = useCommonContext();

  const isValidImagesArray = images && images.length > 0 && images[0].request_status === "Success";

  useEffect(() => {
    if (imageContainerRef.current && images[activeIndex]) {
      const selectedImageElement = imageContainerRef.current.children[activeIndex] as HTMLDivElement;
      const scrollLeft = selectedImageElement.offsetLeft - imageContainerRef.current.offsetLeft;
      imageContainerRef.current.scrollTo({ left: scrollLeft, behavior: "smooth" });
    }
  }, [activeIndex, images]);

  useEffect(() => {
    if (activeIndex >= images.length) {
      setActiveIndex(images.length > 0 ? images.length - 1 : 0);
    }
  }, [activeIndex, images, setActiveIndex]);

  useEffect(() => {
    if (images[activeIndex]?.r2_image) {
      handleImageLoad(activeIndex);
      return;
    }
    if (!images[activeIndex]) return;
    const img = new Image();
    img.src = images[activeIndex]?.url + "public";
    img.onload = () => {
      handleImageLoad(activeIndex);
      setImageSize({ width: img.naturalWidth, height: img.naturalHeight });
    };
  }, [activeIndex, images]);

  const handleImageLoad = (index: number) => {
    setImageLoaded((prev) => {
      const newLoaded = [...prev];
      newLoaded[index] = true;
      return newLoaded;
    });
  };

  const handleThumbnailClick = (index: number) => {
    setActiveIndex(index);
  };

  const handleModalClose = () => {
    setOpen(false);
    setActiveIndex(0);
  };

  const handleNavigationClick = (direction: "prev" | "next") => {
    const newIndex =
      direction === "prev"
        ? activeIndex > 0
          ? activeIndex - 1
          : images.length - 1
        : activeIndex < images.length - 1
          ? activeIndex + 1
          : 0;
    setActiveIndex(newIndex);
  };

  if (!isValidImagesArray) return null;

  const shouldUseZoom = () => {
    if (images[activeIndex]?.r2_image) return false;
    return (
      imageSize.width > containerRef.current?.clientWidth! || imageSize.height > containerRef.current?.clientHeight!
    );
  };

  const openR2Image = () => {
    if (images[activeIndex].r2_image) {
      window.open(images[activeIndex].r2_image, "_blank");
    }
  };

  return (
    <Modal open={open} onClose={handleModalClose}>
      <Paper
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90vw",
          height: "100%",
          maxHeight: { xs: "90vh", md: "90vh" },
          bgcolor: theme.palette.background.paper + "E6",
          boxShadow: 24,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid container sx={{ height: "100%" }}>
          <Grid
            item
            xs={12}
            md={7}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              height: "100%",
              pb: 2,
              pt: 4,
              px: 4,
              position: "relative",
            }}
          >
            <Box
              ref={containerRef}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mb: 2,
                width: "100%",
                height: "100%",
                bgcolor: theme.palette.background.paper,
                overflow: images[activeIndex]?.safe_level > 2 && filtered ? "hidden" : "auto",
              }}
            >
              {imageLoaded[activeIndex] ? (
                <Fade in={imageLoaded[activeIndex]} timeout={1000}>
                  <Box
                    sx={{
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        filter: images[activeIndex]?.safe_level > 2 && filtered ? "blur(80px) brightness(50%)" : "none",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        width: "100%",
                      }}
                    >
                      {shouldUseZoom() ? (
                        <InnerImageZoom
                          src={images[activeIndex]?.url + "public"}
                          zoomSrc={images[activeIndex]?.url + "public"}
                          className="image-zoom"
                        />
                      ) : (
                        <img
                          src={
                            images[activeIndex]?.r2_image
                              ? "/images/oversized-image.jpg"
                              : images[activeIndex]?.url + "public"
                          }
                          style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                            cursor: images[activeIndex]?.r2_image ? "pointer" : "auto",
                          }}
                          alt="selected"
                          onClick={openR2Image}
                        />
                      )}
                    </Box>
                    {images[activeIndex]?.safe_level > 2 && filtered && (
                      <Box
                        sx={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          color: "white",
                          zIndex: 2,
                          width: "100%",
                          height: "auto",
                          p: 4,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            height: "100%",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              mb: 2,
                            }}
                          >
                            <ReportIcon sx={{ mr: 1 }} color={"warning"} />
                            <Typography variant={"body1"}>{t("juno.input.filtered")}</Typography>
                          </Box>
                          <Typography variant={"caption"} whiteSpace={"break-spaces"} sx={{ textAlign: "left" }}>
                            {t("juno.input.filteredDescription")}
                          </Typography>
                        </Box>
                      </Box>
                    )}
                    {images[activeIndex]?.safe_level > 2 && (
                      <Tooltip title={filtered ? t("juno.input.hide") : t("juno.input.show")}>
                        <IconButton
                          onClick={() => setFiltered(!filtered)}
                          sx={{
                            position: "absolute",
                            top: 40,
                            right: 40,
                            zIndex: 3,
                            color: "white",
                            backgroundColor: "rgba(0,0,0,0.3)",
                            "&:hover": {
                              backgroundColor: "rgba(0,0,0,0.5)",
                            },
                          }}
                          aria-label="toggle blur"
                        >
                          {filtered ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                      </Tooltip>
                    )}
                  </Box>
                </Fade>
              ) : (
                <Skeleton variant="rectangular" width="100%" height="100%" />
              )}
            </Box>

            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
              <IconButton onClick={() => handleNavigationClick("prev")} sx={{ mr: 1 }}>
                <ArrowBackIosNewIcon fontSize={"small"} />
              </IconButton>
              <Box
                ref={imageContainerRef}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  overflowX: "auto",
                  gap: 1,
                }}
              >
                {images.map((image, index) => (
                  <Fade in={true} timeout={1000} key={index}>
                    <Box
                      onClick={() => handleThumbnailClick(index)}
                      sx={{
                        width: 44,
                        height: 44,
                        border: activeIndex === index ? `2px solid ${theme.palette.primary.main}` : "none",
                        cursor: "pointer",
                      }}
                    >
                      <Box
                        sx={{
                          width: 40,
                          height: 40,
                          overflow: "hidden",
                        }}
                      >
                        <img
                          src={image.r2_image ? "/images/oversized-image.jpg" : image.url + "public"}
                          alt="thumbnail"
                          style={{
                            width: 40,
                            height: 40,
                            filter: image.safe_level > 2 && filtered ? "blur(8px)" : "none",
                            transform: image.safe_level > 2 && filtered ? "scale(1.5)" : "none",
                            objectFit: "cover",
                          }}
                          loading={"lazy"}
                        />
                      </Box>
                    </Box>
                  </Fade>
                ))}
              </Box>
              <IconButton onClick={() => handleNavigationClick("next")} sx={{ ml: 1 }}>
                <ArrowForwardIosIcon fontSize={"small"} />
              </IconButton>
            </Box>
          </Grid>

          <Grid item xs={12} md={5} p={4} height={"100%"}>
            <ImageModalDetails
              images={images}
              activeIndex={activeIndex}
              setActiveIndex={setActiveIndex}
              setOpen={setOpen}
              open={open}
            />
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
};

export default ImageModal;
