import { Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import FavoriteIcon from "@mui/icons-material/Favorite";
import React from "react";
import Cookies from "js-cookie";

interface Props {
  uuid: string;
  onImage?: boolean;
}

const Like = ({ uuid, onImage = true }: Props) => {
  const [isLiked, setIsLiked] = React.useState(false);

  // 初回レンダリング時にいいねの状態を取得
  React.useEffect(() => {
    getLike();
  }, [uuid]);

  // いいねの状態を取得
  const getLike = async () => {
    try {
      const url = `/api/v1/juno/like?uuid=${uuid}`;
      const csrftoken = Cookies.get("csrftoken");
      const headers = new Headers({
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken!,
      });
      const response = await fetch(url, {
        method: "GET",
        headers: headers,
      });
      const result = await response.json();
      setIsLiked(result.is_liked);
    } catch (e) {
      console.error(e);
    }
  };

  const handleLikeClick = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    try {
      const url = `/api/v1/juno/like`;
      const csrftoken = Cookies.get("csrftoken");
      const headers = new Headers({
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken!,
      });
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify({ uuid: uuid }),
        headers: headers,
      });
      const result = await response.json();
      setIsLiked(result.is_liked);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Tooltip title={"LIKE!"}>
      <IconButton onClick={handleLikeClick}>
        {isLiked ? (
          <FavoriteIcon color="error" fontSize="small" />
        ) : (
          <FavoriteIcon sx={{ color: onImage ? "common.white" : "textPrimary" }} fontSize="small" />
        )}
      </IconButton>
    </Tooltip>
  );
};
export default Like;
