import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import CasinoIcon from "@mui/icons-material/Casino";
import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import { Divider, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useAlert } from "../../../../context/AlertContext";
import { AiEngineList } from "../../../../types/junoTypes";

import { useCheckCredit } from "../../../../hooks/useCreditCheck";
import { setCreditTrigger } from "../../../../redux/slices/triggerSlice";
import InputImagePrompt from "./InputImagePrompt";
import ProPlanModal from "../../../../components/common/ProPlanModal";
import { useParamsContext } from "../../../../context/juno/ParamsContext";
import { useUploadImageContext } from "../../../../context/juno/UploadImageContext";
import { useUpscaleContext } from "../../../../context/juno/UpscaleContext";
import { useCommonContext } from "../../../../context/juno/CommonContext";

type AiEngine = AiEngineList;

interface InputData {
  requestUuid?: string | null;
  action: string;
  prompt: string;
  aiEngine: AiEngine;
  initImage?: string;
  imageStrength?: number;
  width?: number;
  height?: number;
  revisedPrompt?: boolean;
  style?: string;
  step?: number;
  sample?: number;
  guidanceScale?: number;
  negativePrompt?: string;
  seed?: number;
  model?: { name: string; id: string };
  lora?: { name: string; id: string };
  loraStrength?: number;
  ipAdapterImage?: string | null;
  ipAdapterStrength?: number | null;
  uuidParent?: string;
  isPublic?: boolean;
  aspectRatio?: string;
  needFixPrompt: boolean;
  styleFluxJuno?: string;
}

type InputPromptProps = {
  fetchAndSetImageList: (scroll?: boolean) => void;
};

const InputPrompt: React.FC<InputPromptProps> = ({ fetchAndSetImageList }) => {
  const { t } = useTranslation();
  const { checkCredit } = useCheckCredit();
  const theme = useTheme();
  const {
    prompt,
    setPrompt,
    negativePrompt,
    modelSD,
    modelSDXL,
    loraSD,
    revisedPrompt,
    aspectRatio,
    width,
    height,
    step,
    sample,
    guidanceScale,
    seed,
    style,
    styleWeight,
    isPublic,
  } = useParamsContext();

  const { image, setImagePrompt, imageStrength, imagePrompt, imagePromptStrength } = useUploadImageContext();
  const { uuidParent, upscaleFactor, upscaleCreativity, upscaleDetail, upscaleResemblance } = useUpscaleContext();

  const { setProcessing, setRefreshTrigger } = useCommonContext();

  const [localPrompt, setLocalPrompt] = useState<string>("");
  const [rotationDegree, setRotationDegree] = useState(0);
  const { setAlert } = useAlert();
  const params = new URLSearchParams(window.location.search);
  const menu = params.get("menu") || "txt2img";
  const ai_engine = params.get("ai_engine") || "SD";
  const [loadButton, setLoadButton] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const [updating, setUpdating] = useState(false);
  const [openProDialog, setOpenProDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState<string | undefined>(undefined);

  useEffect(() => {
    setLocalPrompt(prompt);
  }, [prompt]);

  useEffect(() => {
    const text = location.state?.textEditor;
    if (text) {
      setLocalPrompt(text as string);
    }
  }, [location.state?.textEditor]);

  const validateContext = (aiEngine: string) => {
    const errorMessages: string[] = [];

    if (localPrompt.trim().length > 1000) {
      errorMessages.push("・" + t("juno.validation.prompt"));
    }

    if (aiEngine === "SD" || aiEngine === "SDXL") {
      if (menu === "img2img" && !image) {
        errorMessages.push("・" + t("juno.validation.image"));
      }
      if (negativePrompt.trim().length > 1000) {
        errorMessages.push("・" + t("juno.validation.negativePrompt"));
      }
    }

    if (aiEngine === "SD" && modelSD === null) {
      errorMessages.push("・" + t("juno.validation.model"));
    }

    if (aiEngine === "SDXL" && modelSDXL === null) {
      errorMessages.push("・" + t("juno.validation.model"));
    }

    // エラーメッセージがある場合、一つのアラートで表示
    if (errorMessages.length > 0) {
      setAlert("error", errorMessages.join("\n"));
    }

    return errorMessages.length === 0;
  };

  // 画像生成APIへのリクエスト
  const handleSend = async (request_uuid?: string | null) => {
    setLoadButton(true);
    try {
      if (!(await checkCredit())) return;
      setProcessing(true);

      const url = "/api/v1/juno/generate";
      const csrftoken = Cookies.get("csrftoken");
      const headers = new Headers({
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken!,
      });

      const aiEngine = ai_engine as AiEngine;

      if (!validateContext(aiEngine)) return;
      if (!validateInputs(aiEngine)) return;

      let data: InputData = {
        action: menu,
        prompt: localPrompt,
        aiEngine: aiEngine,
        requestUuid: request_uuid,
        isPublic: isPublic,
        needFixPrompt: false,
      };

      data = { ...data, ...getAiEngineData(aiEngine, data) };

      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({ data }),
      });
      const res = await response.json();

      handleResponse(res);
    } catch (e) {
      setAlert("error", "Something went wrong. Please try again.");
    } finally {
      dispatch(setCreditTrigger(true));
      fetchAndSetImageList(false);
      setProcessing(false);
    }
  };

  const validateInputs = (aiEngine: AiEngine) => {
    const aiEnginesRequiringImage = ["SD", "SDXL", "Flux1Dev", "SD3", "Ideogram2", "Ideogram2Turbo"];
    if (aiEnginesRequiringImage.includes(aiEngine) && menu === "img2img" && !image) {
      setAlert("error", "Please upload an image.");
      return false;
    }

    const modelRequiredEngines = ["SD", "SDXL"];

    if (modelRequiredEngines.includes(aiEngine)) {
      const selectedModel = aiEngine === "SD" ? modelSD : modelSDXL;
      if (selectedModel === null) {
        setAlert("error", "Please select a model");
        return false;
      }
    }

    return true;
  };

  const getAiEngineData = (aiEngine: AiEngine, data: InputData) => {
    let additionalData = {};
    switch (aiEngine) {
      case "DallE3":
        additionalData = {
          width: width.DallE3,
          height: height.DallE3,
          revisedPrompt: revisedPrompt,
          style: style.DallE3,
        };
        break;
      case "SD":
      case "SDXL":
        const model = aiEngine === "SDXL" ? modelSDXL : modelSD;
        additionalData = {
          width: width[aiEngine],
          height: height[aiEngine],
          negativePrompt: negativePrompt,
          step: step[aiEngine],
          sample: sample[aiEngine],
          guidanceScale: guidanceScale[aiEngine],
          seed: seed[aiEngine],
          model: model,
          lora: loraSD,
          revisedPrompt: false,
          ipAdapterImage: imagePrompt || null,
          ipAdapterStrength: imagePrompt ? imagePromptStrength : null,
        };
        break;
      case "SD3":
      case "SDUltra":
        additionalData = {
          negativePrompt: negativePrompt,
          aspectRatio: aspectRatio[aiEngine],
          seed: seed[aiEngine],
        };
        break;
      case "Flux11Pro":
      case "Flux1Dev":
      case "Flux1Schnell":
      case "Flux1RealismLoRA":
      case "FluxJuno":
        additionalData = {
          aspectRatio: aspectRatio[aiEngine],
          seed: seed[aiEngine],
          guidanceScale: guidanceScale[aiEngine],
          step: step[aiEngine],
          sample: sample[aiEngine],
          style: style[aiEngine],
          styleWeight: styleWeight[aiEngine],
        };
        break;
      case "Ideogram2":
      case "Ideogram2Turbo":
        additionalData = {
          aspectRatio: aspectRatio[aiEngine],
          seed: seed[aiEngine],
          style: style[aiEngine],
          step: step[aiEngine],
          revisedPrompt: revisedPrompt,
        };
        break;
      default:
        additionalData = { width: 1024, height: 1024 };
    }

    if (data.action === "img2img" && image) {
      additionalData = {
        ...additionalData,
        initImage: image,
        imageStrength: imageStrength,
        uuidParent: uuidParent,
      };
    }

    return additionalData;
  };

  //　生成リクエストのUUIDを元に再度リクエストを送信
  const handleResponse = (res: any) => {
    if (res.status === "success") {
      fetchAndSetImageList();
    } else if (res.status === "temporary_success") {
      fetchAndSetImageList(false);
      handleSend(res.request_uuid);
    } else if (res.status === "processing") {
      // webhookを待つ
    } else {
      fetchAndSetImageList();
      if (res.message === "NO_CREDITS") {
        setDialogMessage(t("juno.error.noCredit"));
        setOpenProDialog(true);
      } else if (res.message === "UPGRADE_PLAN") {
        setDialogMessage(t("juno.error.upgrade"));
        setOpenProDialog(true);
      } else {
        setAlert("error", res.message);
      }
    }
  };

  // プロンプト生成APIへのリクエスト
  const generateSampleText = async () => {
    try {
      setUpdating(true);
      if (!(await checkCredit())) return;
      setRotationDegree(rotationDegree + 360);
      setProcessing(true);

      const url = "/api/v1/juno/generate-prompt";
      const csrftoken = Cookies.get("csrftoken");
      const headers = new Headers({
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken!,
      });
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({ aiEngine: ai_engine }),
      });
      await response.json().then((data) => {
        setPrompt(data);
        dispatch(setCreditTrigger(true));
      });
    } catch (e) {
      console.error(e);
    } finally {
      setProcessing(false);
      setUpdating(false);
    }
  };

  // プロンプト生成APIへのリクエスト
  const updatePrompt = async () => {
    try {
      setUpdating(true);
      if (!(await checkCredit())) return;
      setProcessing(true);

      const url = "/api/v1/juno/update-prompt";
      const csrftoken = Cookies.get("csrftoken");
      const headers = new Headers({
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken!,
      });
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          aiEngine: ai_engine,
          prompt: localPrompt,
        }),
      });
      await response.json().then((data) => {
        setPrompt(data);
        dispatch(setCreditTrigger(true));
      });
    } catch (e) {
      console.error(e);
    } finally {
      setProcessing(false);
      setUpdating(false);
    }
  };

  const displayImagePrompt = () => {
    const menu_factor = menu === "txt2img" || menu === "img2img";
    const engine_factor = ai_engine === "SD" || ai_engine === "SDXL";
    return menu_factor && engine_factor;
  };

  useEffect(() => {
    if (loadButton) {
      setTimeout(() => {
        setLoadButton(false);
      }, 2000);
    }
  }, [loadButton]);

  const Updating = () => {
    return (
      <div
        style={{
          width: 40,
          height: 40,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress size={20} color="primary" />
      </div>
    );
  };

  // アップスケールリクエスト
  const handleUpscale = async (request_uuid?: string | null) => {
    try {
      setProcessing(true);

      const url = "/api/v1/juno/generate-upscale";
      const csrftoken = Cookies.get("csrftoken");
      const headers = new Headers({
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken!,
      });

      // 画像サイズをチェックする非同期関数
      const checkImageSize = async (): Promise<boolean> => {
        return new Promise((resolve) => {
          if (image && ai_engine === "CreativeUpscaler") {
            const img = new Image();
            img.src = image;
            img.onload = () => {
              if (img.width * img.height > 4194304) {
                setAlert("error", "The image size is too large.");
                setProcessing(false);
                resolve(false); // サイズが大きすぎる場合、falseを返す
              } else {
                resolve(true); // サイズが問題なければtrueを返す
              }
            };
          } else {
            resolve(true); // サイズチェックが不要な場合はtrueを返す
          }
        });
      };

      // 画像サイズチェックを行う
      const isSizeValid = await checkImageSize();
      if (!isSizeValid) {
        return; // サイズが無効ならfetchを実行しない
      }

      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          action: "upscale",
          aiEngine: ai_engine,
          initImage: image,
          factor: upscaleFactor[ai_engine],
          creativity: upscaleCreativity[ai_engine],
          detail: upscaleDetail[ai_engine],
          resemblance: upscaleResemblance[ai_engine],
          requestUuid: request_uuid,
          uuidParent: uuidParent,
          isPublic: uuidParent ? isPublic : false,
          prompt: localPrompt,
        }),
      });

      const res = await response.json();
      if (res.status === "success") {
        setRefreshTrigger(new Date().getTime());
      } else if (res.status === "temporary_success") {
        setRefreshTrigger(new Date().getTime());
        await handleUpscale(res.request_uuid);
      } else {
        setAlert("error", res.message);
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(setCreditTrigger(true));
      setProcessing(false);
    }
  };

  // 送信ボタンの色を変更
  const getSendIconColor = () => {
    if (menu === "upscale") {
      return "secondary";
    }
    return localPrompt.length === 0 && ai_engine !== "AuraSR" ? "disabled" : "secondary";
  };

  const placeholder = () => {
    if (ai_engine === "AuraSR") {
      return t("juno.history.notNeededPlaceholder");
    }
    if (menu === "upscale") {
      return t("juno.history.optionalPromptPlaceholder");
    }
    return t("juno.history.promptPlaceholder");
  };

  return (
    <>
      <Box sx={{ px: 2, pb: 1, mt: 2 }}>
        <TextField
          label={t("juno.history.input")}
          variant="outlined"
          autoComplete={"off"}
          placeholder={placeholder()}
          autoFocus
          value={localPrompt}
          onChange={(event) => setLocalPrompt(event.target.value)}
          disabled={ai_engine === "AuraSR"}
          multiline
          fullWidth
          maxRows={4}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Box sx={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
                  {localPrompt.trim().length === 0 ? (
                    updating ? (
                      <Updating />
                    ) : (
                      // サンプルプロンプト生成
                      <Tooltip title={t("juno.history.dice")}>
                        <span>
                          <IconButton onClick={generateSampleText} disabled={ai_engine === "AuraSR" || updating}>
                            <CasinoIcon
                              sx={{
                                transform: `rotate(${rotationDegree}deg)`,
                                transition: "transform 1s ease-in-out",
                              }}
                            />
                          </IconButton>
                        </span>
                      </Tooltip>
                    )
                  ) : // プロンプトアップデート
                  updating ? (
                    <Updating />
                  ) : (
                    <Tooltip title={t("juno.history.improve")}>
                      <span>
                        <IconButton onClick={() => updatePrompt()} disabled={ai_engine === "AuraSR" || updating}>
                          <AutoFixHighIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  )}
                  {/* delete */}
                  <Tooltip title={t("common.delete")}>
                    <span>
                      <IconButton
                        onClick={() => {
                          setImagePrompt(null);
                          setLocalPrompt("");
                        }}
                        disabled={ai_engine === "AuraSR"}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </span>
                  </Tooltip>

                  {/* IP_Adapter */}
                  {displayImagePrompt() && <InputImagePrompt />}

                  <Divider orientation="vertical" sx={{ height: "28px", mx: 0.5 }} variant={"middle"} />
                </Box>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end" color="secondary">
                <IconButton
                  disabled={localPrompt.trim().length === 0 && menu !== "upscale"}
                  onClick={
                    menu === "upscale" ? () => handleUpscale() : localPrompt.length > 0 ? () => handleSend() : undefined
                  }
                >
                  {loadButton ? (
                    <CircularProgress size={20} color="primary" />
                  ) : (
                    <SendIcon color={getSendIconColor()} />
                  )}
                </IconButton>
              </InputAdornment>
            ),
            sx: { borderRadius: 5, backgroundColor: theme.palette.background.paper },
            inputProps: { maxLength: 1000 },
          }}
        />
      </Box>
      <ProPlanModal open={openProDialog} setOpen={setOpenProDialog} message={dialogMessage} />
    </>
  );
};
export default InputPrompt;
