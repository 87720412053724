import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import { Box } from "@mui/material";
import ButtonBase from "@mui/material/ButtonBase";
import { alpha, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useCustomNavigate } from "../../../hooks/useCustomNavigate";
import { GradientTypography } from "../../../utils/gradientTypography";
import { ColumnBox } from "../../../utils/styledBox";
import React from "react";

const Home = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useCustomNavigate();
  const theme = useTheme();

  const fontWeight = {
    fontWeight: location.pathname.split("/")[2] === "notes-nebula" ? 600 : 400,
  };

  return (
    <Box>
      {/* Home */}
      <Box px={1} pb={1}>
        <ButtonBase
          onClick={() => navigate("")}
          sx={{
            height: 50,
            borderRadius: 1,
            width: "100%",
            textAlign: "left",
            backgroundColor:
              location.pathname.split("/")[2] === undefined ? alpha(theme.palette.primary.main, 0.2) : "transparent",
            "&:hover": {
              backgroundColor: alpha(theme.palette.primary.light, 0.1),
            },
          }}
        >
          <Box pl={2} display={"inline-flex"} justifyContent={"flex-start"} alignItems={"center"} width={"100%"}>
            <img src="/images/logo/logoZI-small.png" style={{ width: 24, height: 24, marginLeft: -4 }} alt="Tenak.ai" />
            <Typography
              variant="body1"
              component="span"
              sx={{
                fontSize: "1.2rem",
                fontFamily: "'Pacifico', cursive",
                fontWeight: location.pathname.split("/")[2] === undefined ? 500 : 300,
                pl: 4.5,
              }}
            >
              {t("drawer.home")}
            </Typography>
          </Box>
        </ButtonBase>
      </Box>

      {/* Notes Nebula */}
      <Box px={1}>
        <ButtonBase
          onClick={() => navigate("/notes-nebula")}
          sx={{
            height: 50,
            borderRadius: 1,
            width: "100%",
            textAlign: "left",
            backgroundColor: (theme) =>
              location.pathname.split("/")[2]?.startsWith("notes-nebula")
                ? alpha(theme.palette.primary.main, 0.2)
                : "transparent",
            "&:hover": {
              backgroundColor: (theme) => alpha(theme.palette.primary.light, 0.1),
            },
          }}
        >
          <Box pl={2} display={"inline-flex"} justifyContent={"flex-start"} alignItems={"center"} width={"100%"}>
            <TipsAndUpdatesIcon fontSize={"small"} color={"secondary"} sx={{ mr: 4.5 }} />
            <ColumnBox>
              <Box sx={{ display: "inline" }}>
                <GradientTypography variant="body1" component="span" sx={{ ...fontWeight }}>
                  N
                </GradientTypography>
                <Typography variant="body1" component="span" sx={{ ...fontWeight }}>
                  otes&nbsp;
                </Typography>
                <GradientTypography variant="body1" component="span" sx={{ ...fontWeight }}>
                  N
                </GradientTypography>
                <Typography variant="body1" component="span" sx={{ ...fontWeight }}>
                  ebula
                </Typography>
              </Box>
              <Typography variant="caption" component="span">
                {t("drawer.notesNebulaDescription")}
              </Typography>
            </ColumnBox>
          </Box>
        </ButtonBase>
      </Box>
    </Box>
  );
};
export default Home;
