import React from "react";
import { Box, Grid, Paper, Tooltip, Typography, useTheme } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import HelpIcon from "@mui/icons-material/Help";
import Skeleton from "@mui/material/Skeleton";
import PublicIcon from "@mui/icons-material/Public";

interface SearchResult {
  title: string;
  snippet: string;
  snippet_highlighted_words: string[];
  url: string;
  displayed_link: string;
  favicon: string;
  google_url: string;
  keyword: string;
}

interface SearchContent {
  organic_results: SearchResult[];
  google_url: string;
  keyword: string;
}

export default function SearchBox({ content }: { content: SearchContent }) {
  const { t } = useTranslation();
  const theme = useTheme();

  const hoverStyles = {
    transition: "all 0.3s ease-in-out",
    "&:hover": {
      transform: "translateY(-5px)",
      // backgroundColor: theme.palette.mode === 'dark' ? 'rgba(0, 0, 0, 0.2)' : '#fff'
      opacity: 0.9,
    },
  };

  const buttonVariant = theme.palette.mode === "dark" ? "outlined" : "contained";

  return (
    <>
      <Typography
        variant="h5"
        color="textSecondary"
        mb={2}
        fontWeight={800}
        sx={{ maxWidth: 400, textAlign: "center" }}
      >
        {t("browsing.sidebar.preSearchTitle")}
      </Typography>

      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <SearchIcon />
        <Typography
          variant="body1"
          sx={{
            ml: 1,
            fontWeight: "bold",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {content ? content.keyword : <Skeleton variant="rounded" width={200} height={36} />}
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <Tooltip title={t("browsing.searchHelp")}>
          <IconButton size="small" sx={{ ml: 0, color: "textprimary" }}>
            <HelpIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "nowrap",
          py: 1,
          height: "100%",
          px: 1,
          width: "100%",
          overflow: "auto",
        }}
      >
        {content && (
          <Grid container spacing={2} sx={{ mb: 2 }}>
            {content.organic_results ? (
              content.organic_results.map((result, index) => (
                <Grid item xs={6} key={index}>
                  <Paper
                    elevation={0}
                    sx={{
                      borderRadius: "4px",
                      minWidth: { xs: "80%", sm: "10px" },
                      ...hoverStyles,
                      backgroundColor: "background.custom1",
                    }}
                  >
                    <Link
                      to={result.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        color: theme.palette.text.secondary,
                        textDecoration: "none",
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                      }}
                    >
                      <Box
                        display={"flex"}
                        flexDirection={"column"}
                        sx={{
                          color: theme.palette.text.secondary,
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          background: "linear-gradient(115deg, rgba(73, 56, 186,1) 0%, rgba(206, 63, 235,1) 100%)",
                          borderRadius: "4px 4px 0 0",
                          height: 80,
                        }}
                      >
                        {result.favicon ? (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              backgroundImage: `url(${result.favicon})`,
                              backgroundSize: "cover",
                              height: 80,
                              width: "100%",
                              borderRadius: "4px 0 0 0",
                              backgroundPosition: "center",
                            }}
                          />
                        ) : (
                          <PublicIcon sx={{ fontSize: 40, color: "white" }} />
                        )}
                      </Box>
                      {/* content */}
                      <Box p={1} gap={1} sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
                        <Typography
                          variant="body2"
                          sx={{
                            color: "white",
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 2,
                            overflow: "hidden",
                            minHeight: 40,
                          }}
                          dangerouslySetInnerHTML={{ __html: result.title }}
                        />

                        <Box sx={{ maxHeight: 200, overflowY: "hidden" }}>
                          <Typography
                            variant="caption"
                            style={{
                              overflowWrap: "break-word",
                              display: "-webkit-box",
                              WebkitBoxOrient: "vertical",
                              WebkitLineClamp: 3,
                              overflow: "hidden",
                            }}
                            dangerouslySetInnerHTML={{
                              __html: result.snippet,
                            }}
                          />
                        </Box>
                        <Box sx={{ width: "100%", display: "flex", justifyContent: "right" }}>
                          <Typography
                            variant="caption"
                            sx={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {result.displayed_link}
                          </Typography>
                        </Box>
                      </Box>
                    </Link>
                  </Paper>
                </Grid>
              ))
            ) : (
              <Box sx={{ display: "flex", justifyContent: "center", width: "100%", m: 0, p: 1 }}>
                <Typography variant="body1">{t("browsing.error.search")}</Typography>
              </Box>
            )}
          </Grid>
        )}
        {!content && (
          <>
            {Array(10)
              .fill(0)
              .map((_, index) => (
                <Paper
                  key={index}
                  sx={{ borderRadius: "4px", minWidth: { xs: "80%", sm: "240px" }, ...hoverStyles }}
                  elevation={0}
                >
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    gap={2}
                    sx={{
                      color: theme.palette.text.secondary,
                      height: "56px",
                      width: "100%",
                      background: "linear-gradient(115deg, rgba(73, 56, 186,1) 0%, rgba(206, 63, 235,1) 100%)",
                      borderRadius: "4px 4px 0 0",
                      p: 2,
                    }}
                  >
                    <Skeleton variant="rounded" width={"100%"} height={28} />
                  </Box>
                  <Skeleton variant="rounded" sx={{ minWidth: { xs: "80%", sm: "240px" }, height: "120px" }} />
                </Paper>
              ))}
          </>
        )}
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
        {content ? (
          <>
            {content.organic_results && (
              <Button
                disableElevation
                component={Link}
                to={content.google_url}
                variant={buttonVariant}
                color="primary"
                target="_blank"
                rel="noopener noreferrer"
                endIcon={<OpenInNewIcon />}
              >
                {t("browsing.searchMore")}
              </Button>
            )}
          </>
        ) : (
          <Skeleton animation="wave" variant="rounded" width={240} height={36} />
        )}
      </Box>
    </>
  );
}
