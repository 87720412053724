import React from "react";
import { Box, Grid, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useCustomNavigate } from "../../../hooks/useCustomNavigate";
import FolderIcon from "@mui/icons-material/Folder";
import ImageIcon from "@mui/icons-material/Image";
import PhotoSizeSelectLargeIcon from "@mui/icons-material/PhotoSizeSelectLarge";
import FontDownloadIcon from "@mui/icons-material/FontDownload";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ButtonAI from "./ButtonAI";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";

const Image = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useCustomNavigate();

  const ImageAIs = [
    {
      title: t("juno.quick.title"),
      icon: <ElectricBoltIcon />,
      path: "/juno/quick-generator",
    },
    {
      title: t("juno.menu.txt2img"),
      icon: <FontDownloadIcon />,
      path: "/juno/generator?menu=txt2img&ai_engine=DallE3",
    },
    {
      title: t("juno.menu.img2img"),
      icon: <ImageIcon />,
      path: "/juno/generator?menu=img2img&ai_engine=SD3",
    },
    {
      title: t("juno.menu.upscale"),
      icon: <PhotoSizeSelectLargeIcon />,
      path: "/juno/generator?menu=upscale&ai_engine=RealESRGAN",
    },
  ];

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container spacing={1}>
        {/* 画像生成 */}
        <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant={"h6"}
            component={"h3"}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <ImageIcon sx={{ color: theme.palette.secondary.main, mr: 2 }} />
            {t("dashboard.image")}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Tooltip title={t("dashboard.library")} placement="left">
            <IconButton onClick={() => navigate("/juno/gallery")} sx={{ color: theme.palette.primary.main }}>
              <FolderIcon />
            </IconButton>
          </Tooltip>
        </Grid>

        <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
          <Box
            sx={{
              width: "100%",
              height: 170,
              backgroundImage: `url(/images/juno/top.jpg)`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              borderRadius: 2,
              cursor: "pointer",
              "&:hover": {
                transform: "translateY(-5px)",
              },
              transition: "all 0.3s ease",
            }}
            onClick={() => navigate("/juno")}
          />
        </Grid>

        {ImageAIs.map((ai, index) => {
          return (
            <Grid item xs={12} md={6} key={index}>
              <ButtonAI title={ai.title} icon={ai.icon} path={ai.path} image={`image_${index + 1}`} />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};
export default Image;
