import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import ImageIcon from "@mui/icons-material/Image";
import PhotoSizeSelectLargeIcon from "@mui/icons-material/PhotoSizeSelectLarge";
import RepeatIcon from "@mui/icons-material/Repeat";
import { CircularProgress, Divider, Grid, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import CopyButtonOrIcon from "../../../../components/common/CopyButtonOrIcon";
import { ImageInfo } from "../../../../types/junoTypes";
import { setCreditTrigger } from "../../../../redux/slices/triggerSlice";
import { localDate } from "../../../../utils/utils";
import ActionLabels from "./ActionLabels";
import HistoryImageSlider from "./HistoryImageSlider";
import ImageModal from "./ImageModal";
import InputPrompt from "./InputPrompt";
import { useCommonContext } from "../../../../context/juno/CommonContext";
import { useJunoMethods } from "../../../../hooks/useJunoMethods";

export default function History() {
  const { t } = useTranslation();
  const theme = useTheme();
  const { fetchHistory, fetchQueuedImages, handleDownload, handleImg2Img, handleUpscale, handleSetSameParams } =
    useJunoMethods();

  const { refreshTrigger, setRefreshTrigger } = useCommonContext();

  const [requests, setRequests] = useState<[ImageInfo][]>([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedUuid, setSelectedUuid] = useState<string | null>(null);
  const [selectedImageUuid, setSelectedImageUuid] = useState<string | null>(null);
  const [openModal, setOpenModal] = React.useState(false);
  const dispatch = useDispatch();
  const uiLanguage = Cookies.get("ui_language") || "en";
  const [settingI2I, setSettingI2I] = useState(false);

  // 画像一覧取得
  const fetchAndSetImageList = async (scroll = true) => {
    try {
      const json = await fetchHistory();
      let flat_json = json.flat();
      flat_json = flat_json.filter((image) => image.request_status === "Success");
      setModalImages(flat_json);
      setRequests(json);
      if (scroll) {
        scrollToBottom();
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    // modalImagesの何番目にあるかさがす
    if (selectedImageUuid) {
      const index = modalImages.findIndex((image) => image.uuid === selectedImageUuid);
      setModalActiveIndex(index);
    }
  }, [openModal]);

  // マウント時、画像一覧取得
  useEffect(() => {
    fetchAndSetImageList();
  }, [refreshTrigger]);

  useEffect(() => {
    // 15秒後に8秒毎のFetch
    let intervalId: number | null = null;
    const engines = ["SD", "SDXL", "RealESRGAN"];
    const startTimers = () => {
      intervalId = window.setInterval(() => {
        requests.forEach((request) => {
          if (
            request[0].request_status === "Processing" &&
            engines.some((engine) => request[0].params["ai_engine"].includes(engine))
          ) {
            fetchQueuedImages(request[0].request_uuid, request[0].params["action"]).then((res) => {
              if (res["updated"]) {
                setRefreshTrigger(new Date().getTime());
                dispatch(setCreditTrigger(true));
              }
            });
          }
        });
      }, 8000);
    };
    const timeoutId = setTimeout(startTimers, 15000);
    return () => {
      clearTimeout(timeoutId);
      if (intervalId !== null) {
        clearInterval(intervalId);
      }
    };
  }, [requests, refreshTrigger]);

  // リクエストヒストリー削除処理
  const handleConfirmedDelete = async (request_uuid: string) => {
    try {
      const url = "/api/v1/juno/generated-images";
      const csrftoken = Cookies.get("csrftoken");
      const headers = new Headers({
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken!,
      });
      fetch(url, {
        method: "DELETE",
        headers: headers,
        body: JSON.stringify({ uuid: request_uuid }),
      });

      // リストからも削除
      const newList = requests.filter((item) => {
        return item[0].request_uuid !== request_uuid;
      });
      setRequests(newList);
    } catch (e) {
      console.error(e);
    }
  };

  // 削除確認ダイアログを開く
  const handleDialogOpen = (uuid: string) => {
    setSelectedUuid(uuid);
    setDialogOpen(true);
  };

  // 読み込み時に一番下までスクロール.
  const scrollToBottom = () => {
    setTimeout(() => {
      const container = document.getElementById("history-container");
      if (container) {
        container.scrollTo({
          top: container.scrollHeight,
          behavior: "smooth", // スクロールを滑らかにする
        });
      }
    }, 100);
  };

  const [activeIndexes, setActiveIndexes] = useState<{ [request_uuid: string]: number }>({});
  const updateActiveIndex = (request_uuid: string, newIndex: number) => {
    setActiveIndexes((prev) => ({ ...prev, [request_uuid]: newIndex }));
  };

  const [modalImages, setModalImages] = React.useState<ImageInfo[]>([]);
  const [modalActiveIndex, setModalActiveIndex] = React.useState(0);

  const rowNums = (length: number, action) => {
    if (action === "img2img") {
      return length > 1 ? 3 : 2;
    } else {
      return length > 1 ? 5 : 3;
    }
  };

  const [downloading, setDownloading] = useState<{ [request_uuid: string]: boolean }>({});
  const handleDownloadImg = async (request) => {
    try {
      setDownloading((prev) => ({ ...prev, [request[0]["request_uuid"]]: true }));
      const res = await handleDownload(request[activeIndexes[request[0]["request_uuid"]] ?? 0]["uuid"]);
      if (res["success"]) {
        setDownloading((prev) => ({ ...prev, [request[0]["request_uuid"]]: false }));
      }
    } catch (e) {
      console.error(e);
      setDownloading((prev) => ({ ...prev, [request[0]["request_uuid"]]: false }));
    }
  };

  const setImg2Img = async (request) => {
    try {
      setSettingI2I(true);
      const res = await handleImg2Img(
        request[activeIndexes[request[0]["request_uuid"]] ?? 0]["uuid"],
        request[0].params
      );
      if (res["success"]) {
        setSettingI2I(false);
      }
    } catch (e) {
      console.error(e);
      setSettingI2I(false);
    }
  };

  return (
    <>
      <Paper sx={{ height: "100%" }} elevation={theme.palette.mode === "dark" ? 1 : 0}>
        <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
          <Box
            sx={{
              justifyContent: "center",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              my: 1,
            }}
          >
            <Typography variant={"subtitle2"} component={"h3"}>
              {t("juno.history.title")}
            </Typography>
          </Box>
          <Divider />
          <Box id={"history-container"} sx={{ overflowY: "auto", height: "100%", p: 2 }}>
            {requests.map((request, index) => {
              const params = request[0].params;
              return (
                <Box key={index} mt={2}>
                  {index !== 0 && <Divider sx={{ my: 3 }} />}
                  <Grid container spacing={1} sx={{ display: "flex", flexDirection: "row" }}>
                    <Grid item md={3.5} xs={12} sx={{ pr: 1 }}>
                      <HistoryImageSlider
                        images={request as ImageInfo[]}
                        activeIndex={activeIndexes[request[0]["request_uuid"]] || 0}
                        setActiveIndex={(newIndex) => updateActiveIndex(request[0]["request_uuid"], newIndex)}
                        setOpenModal={setOpenModal}
                        setSelectedImageUuid={setSelectedImageUuid}
                      />
                    </Grid>
                    <Grid item md={8.5} xs={12}>
                      <Box sx={{ display: "flex", flexDirection: "row", mb: 4, overflow: "auto" }} gap={1}>
                        <ActionLabels params={params} />
                        <Box flexGrow={1} />
                        <Box>
                          <Typography variant={"body2"} component={"p"} sx={{ py: 1, whiteSpace: "nowrap" }}>
                            {request[0]["created_at"] && localDate(request[0]["created_at"], uiLanguage)}
                          </Typography>
                        </Box>
                        <Box>
                          <Tooltip title={t("common.delete")} placement={"top"}>
                            <span>
                              <IconButton
                                color={"error"}
                                disabled={request[0].request_status === "Processing"}
                                onClick={() => handleDialogOpen(request[0].request_uuid)}
                              >
                                <DeleteIcon fontSize={"small"} />
                              </IconButton>
                            </span>
                          </Tooltip>
                        </Box>
                      </Box>

                      {/* プロンプト */}
                      <Box sx={{ display: "flex", flexDirection: "row", mb: 2, width: "100%" }} gap={1}>
                        {params["prompt"] && (
                          <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                            <TextField
                              label={t("juno.history.prompt")}
                              fullWidth
                              multiline
                              rows={rowNums(request.length, params["action"])}
                              value={params["prompt"]}
                              InputProps={{
                                readOnly: true,
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Box>
                        )}

                        {/* ネガティブプロンプト */}
                        {params["negative_prompt"] && (
                          <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                            <TextField
                              label={t("juno.input.negativePromptHistory")}
                              fullWidth
                              multiline
                              rows={rowNums(request.length, params["action"])}
                              value={params["negative_prompt"]}
                              InputProps={{ readOnly: true }}
                              InputLabelProps={{ shrink: true }}
                            />
                          </Box>
                        )}
                      </Box>

                      {/* 一行に表示するパラメータ */}
                      <Grid container spacing={2} sx={{ mb: 4 }}>
                        {/* サイズ */}
                        {params["width"] && params["height"] && (
                          <Grid item xs={3}>
                            <TextField
                              value={`W: ${params["width"]}  x  H: ${params["height"]}`}
                              variant={"standard"}
                              label={t("juno.history.size")}
                              InputLabelProps={{ shrink: true }}
                              InputProps={{ readOnly: true }}
                              size={"small"}
                              fullWidth
                            />
                          </Grid>
                        )}

                        {/* アスペクト比 */}
                        {params["aspect_ratio"] && (
                          <Grid item xs={3}>
                            <TextField
                              value={params["aspect_ratio"]}
                              variant={"standard"}
                              label={t("juno.input.aspectRatio.title")}
                              InputProps={{ readOnly: true }}
                              InputLabelProps={{ shrink: true }}
                              size={"small"}
                              fullWidth
                            />
                          </Grid>
                        )}

                        {/* Upscale */}
                        {params["action"] === "upscale" && (
                          <>
                            {/* スケールサイズ*/}
                            <Grid item xs={3}>
                              <TextField
                                value={params["factor"]}
                                variant={"standard"}
                                label={t("juno.input.scale")}
                                InputProps={{
                                  readOnly: true,
                                  startAdornment: (
                                    <InputAdornment position="start" color="secondary">
                                      x
                                    </InputAdornment>
                                  ),
                                }}
                                InputLabelProps={{ shrink: true }}
                                size={"small"}
                                fullWidth
                              />
                            </Grid>
                            {params["creativity"] && (
                              <Grid item xs={3}>
                                <TextField
                                  value={params["creativity"]}
                                  variant={"standard"}
                                  label={t("juno.input.creativity")}
                                  InputProps={{ readOnly: true }}
                                  size={"small"}
                                  fullWidth
                                />
                              </Grid>
                            )}
                            {params["detail"] && (
                              <Grid item xs={3}>
                                <TextField
                                  value={params["detail"]}
                                  variant={"standard"}
                                  label={t("juno.input.detail")}
                                  InputProps={{ readOnly: true }}
                                  size={"small"}
                                  fullWidth
                                />
                              </Grid>
                            )}
                            {params["resemblance"] && (
                              <Grid item xs={3}>
                                <TextField
                                  value={params["resemblance"]}
                                  variant={"standard"}
                                  label={t("juno.input.resemblance")}
                                  InputProps={{ readOnly: true }}
                                  size={"small"}
                                  fullWidth
                                />
                              </Grid>
                            )}
                          </>
                        )}

                        {/* スタイル */}
                        {params["style"] && (
                          <Grid item xs={3}>
                            <TextField
                              value={params["style"]}
                              variant={"standard"}
                              label={t("juno.input.style.title")}
                              InputProps={{ readOnly: true }}
                              size={"small"}
                              fullWidth
                            />
                          </Grid>
                        )}

                        {/* スタイル重さ */}
                        {params?.["style_weight"] !== undefined && params?.["style_weight"] !== null && (
                          <Grid item xs={3}>
                            <TextField
                              value={params["style_weight"]}
                              variant={"standard"}
                              label={t("juno.input.style.weight")}
                              InputProps={{ readOnly: true }}
                              size={"small"}
                              fullWidth
                            />
                          </Grid>
                        )}

                        {/*  img2img  */}
                        {params["image_strength"] && (
                          <Grid item xs={3}>
                            <TextField
                              value={params["image_strength"]}
                              variant={"standard"}
                              label={t("juno.input.imageStrength")}
                              InputProps={{ readOnly: true }}
                              size={"small"}
                              fullWidth
                            />
                          </Grid>
                        )}

                        {/* 画像プロンプトの影響度 */}
                        {params["image_prompt_strength"] && (
                          <Grid item xs={3}>
                            <TextField
                              value={params["image_prompt_strength"]}
                              variant={"standard"}
                              label={t("juno.history.ip.strength")}
                              InputProps={{ readOnly: true }}
                              size={"small"}
                              fullWidth
                            />
                          </Grid>
                        )}

                        {/* Lora影響度 */}
                        {params["lora"] && params["lora"]["name"] && (
                          <Grid item xs={3}>
                            <TextField
                              value={params["lora_strength"]}
                              variant={"standard"}
                              label={t("juno.input.loraStrength")}
                              InputProps={{ readOnly: true }}
                              size={"small"}
                              fullWidth
                            />
                          </Grid>
                        )}

                        {/*  ステップ数  */}
                        {params["step"] && (
                          <Grid item xs={3}>
                            <TextField
                              value={params["step"]}
                              variant={"standard"}
                              label={t("juno.input.step")}
                              InputProps={{ readOnly: true }}
                              size={"small"}
                              fullWidth
                            />
                          </Grid>
                        )}

                        {/*  guidanceScale  */}
                        {params["guidance_scale"] && (
                          <Grid item xs={3}>
                            <TextField
                              value={params["guidance_scale"]}
                              variant={"standard"}
                              label={t("juno.input.guidanceScale")}
                              InputProps={{ readOnly: true }}
                              size={"small"}
                              fullWidth
                            />
                          </Grid>
                        )}

                        {/*  seed  */}
                        {params["seed"] > 0 && (
                          <Grid item xs={3}>
                            <TextField
                              value={params["seed"]}
                              variant={"standard"}
                              label={t("juno.input.seedSD")}
                              fullWidth
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end" color="secondary">
                                    <CopyButtonOrIcon displayType={"icon"} textToCopy={params["seed"]} size={"small"} />
                                  </InputAdornment>
                                ),
                                readOnly: true,
                              }}
                              size={"small"}
                            />
                          </Grid>
                        )}
                      </Grid>

                      {/* アクション */}
                      <Grid container spacing={1}>
                        {/*<Box flexGrow={1}/>*/}
                        <Grid item xs={12} sm={3}>
                          <Button
                            disableElevation
                            variant={"outlined"}
                            sx={{ mr: 1 }}
                            fullWidth
                            onClick={() =>
                              handleUpscale(request[activeIndexes[request[0]["request_uuid"]] ?? 0]["uuid"])
                            }
                            disabled={
                              params["width"] > 3000 ||
                              params["height"] > 3000 ||
                              request[0].request_status === "Processing" ||
                              request[0].request_status === "Failure" ||
                              !!request[0].r2_image
                            }
                          >
                            <PhotoSizeSelectLargeIcon fontSize="small" sx={{ mr: 1 }} />
                            <Typography variant={"button"}>{t("juno.history.upscale")}</Typography>
                          </Button>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <Button
                            disableElevation
                            variant={"outlined"}
                            sx={{ mr: 1 }}
                            fullWidth
                            onClick={() => {
                              setImg2Img(request);
                            }}
                            disabled={
                              request[0].request_status === "Processing" ||
                              request[0].request_status === "Failure" ||
                              !!request[0].r2_image ||
                              settingI2I
                            }
                          >
                            <ImageIcon fontSize="small" sx={{ mr: 1 }} />
                            <Typography variant={"button"}>{t("juno.history.img2img")}</Typography>
                          </Button>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <Button
                            disableElevation
                            variant={"outlined"}
                            sx={{ mr: 1 }}
                            fullWidth
                            onClick={() => handleSetSameParams(params)}
                            disabled={params["action"] === "upscale"}
                          >
                            <RepeatIcon fontSize="small" sx={{ mr: 1 }} />
                            <Typography variant={"button"}>{t("juno.history.useParams")}</Typography>
                          </Button>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <Button
                            disableElevation
                            variant={"contained"}
                            sx={{ mr: 1 }}
                            fullWidth
                            onClick={() => handleDownloadImg(request)}
                            disabled={
                              request[0].request_status === "Processing" ||
                              request[0].request_status === "Failure" ||
                              downloading[request[0]["request_uuid"]]
                            }
                            startIcon={
                              downloading[request[0]["request_uuid"]] ? (
                                <CircularProgress size={20} />
                              ) : (
                                <DownloadIcon fontSize="small" />
                              )
                            }
                          >
                            <Typography variant={"button"}>{t("juno.history.download")}</Typography>
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              );
            })}
          </Box>

          {/* 削除確認ダイアログ */}
          <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
            <DialogTitle>{t("juno.history.delete.title")}</DialogTitle>
            <DialogContent>
              <DialogContentText>{t("juno.history.delete.description")}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button disableElevation onClick={() => setDialogOpen(false)} color="primary">
                {t("common.cancel")}
              </Button>
              <Button
                onClick={() => {
                  handleConfirmedDelete(selectedUuid!);
                  setDialogOpen(false);
                }}
                color="error"
              >
                {t("common.delete")}
              </Button>
            </DialogActions>
          </Dialog>
          <Box flexGrow={1} />
          <InputPrompt fetchAndSetImageList={fetchAndSetImageList} />
        </Box>
      </Paper>

      {openModal && (
        <ImageModal
          images={modalImages}
          activeIndex={modalActiveIndex}
          setActiveIndex={setModalActiveIndex}
          open={openModal}
          setOpen={setOpenModal}
        />
      )}
    </>
  );
}
