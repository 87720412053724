// ParamsContext.tsx
import React, { createContext, useContext, useState } from "react";
import { AiEngineList, AiEngineNumber, AspectRatios, LoraSDType, ModelSDType, Style } from "../../types/junoTypes";

type ParamsContextType = {
  prompt: string;
  setPrompt: React.Dispatch<React.SetStateAction<string>>;
  negativePrompt: string;
  setNegativePrompt: React.Dispatch<React.SetStateAction<string>>;
  revisedPrompt: boolean;
  setRevisedPrompt: React.Dispatch<React.SetStateAction<boolean>>;
  style: Style;
  setStyle: React.Dispatch<React.SetStateAction<Style>>;
  styleWeight: AiEngineNumber;
  setStyleWeight: React.Dispatch<React.SetStateAction<AiEngineNumber>>;
  modelSD: ModelSDType | null;
  setModelSD: React.Dispatch<React.SetStateAction<ModelSDType | null>>;
  modelSDXL: ModelSDType | null;
  setModelSDXL: React.Dispatch<React.SetStateAction<ModelSDType | null>>;
  loraSD: LoraSDType[];
  setLoraSD: React.Dispatch<React.SetStateAction<LoraSDType[]>>;
  width: AiEngineNumber;
  setWidthPartial: (key: AiEngineList, value: number) => void;
  height: AiEngineNumber;
  setHeightPartial: (key: AiEngineList, value: number) => void;
  aspectRatio: AspectRatios;
  setAspectRatio: React.Dispatch<React.SetStateAction<AspectRatios>>;
  guidanceScale: AiEngineNumber;
  setGuidanceScale: React.Dispatch<React.SetStateAction<AiEngineNumber>>;
  step: AiEngineNumber;
  setStep: React.Dispatch<React.SetStateAction<AiEngineNumber>>;
  sample: AiEngineNumber;
  setSample: React.Dispatch<React.SetStateAction<AiEngineNumber>>;
  seed: AiEngineNumber;
  setSeed: React.Dispatch<React.SetStateAction<AiEngineNumber>>;
  isPublic: boolean;
  setIsPublic: React.Dispatch<React.SetStateAction<boolean>>;
};

const ParamsContext = createContext<ParamsContextType | undefined>(undefined);

export const ParamsProvider: React.FunctionComponent<{ children?: React.ReactNode }> = ({ children }) => {
  const [prompt, setPrompt] = useState<string>("");
  const [negativePrompt, setNegativePrompt] = useState<string>("");
  const [revisedPrompt, setRevisedPrompt] = useState<boolean>(true);
  const [style, setStyle] = useState<Style>({
    DallE3: "vivid",
    Ideogram2: "GENERAL",
    Ideogram2Turbo: "GENERAL",
    FluxJuno: "ANIME",
  });
  const [styleWeight, setStyleWeight] = useState<AiEngineNumber>({
    FluxJuno: 0.8,
  });
  const [modelSD, setModelSD] = useState<ModelSDType | null>(null);
  const [modelSDXL, setModelSDXL] = useState<ModelSDType | null>(null);
  const [loraSD, setLoraSD] = useState<LoraSDType[]>([]);

  const [width, setWidth] = useState<AiEngineNumber>({
    DallE3: 1024,
    SD: 768,
    SDXL: 1024,
  });
  const [height, setHeight] = useState<AiEngineNumber>({
    DallE3: 1024,
    SD: 768,
    SDXL: 1024,
  });
  const [aspectRatio, setAspectRatio] = useState<AspectRatios>({
    SD3: "1:1",
    SDUltra: "1:1",
    Flux11Pro: "1:1",
    Flux1Dev: "1:1",
    Flux1Schnell: "1:1",
    Flux1RealismLoRA: "1:1",
    FluxJuno: "1:1",
    Ideogram2: "1:1",
    Ideogram2Turbo: "1:1",
  });
  const [guidanceScale, setGuidanceScale] = useState<AiEngineNumber>({
    SD: 6.5,
    SDXL: 6.5,
    Flux1Dev: 3.5,
    Flux1Schnell: 3.5,
    Flux1RealismLoRA: 3.5,
    FluxJuno: 3.5,
  });
  const [step, setStep] = useState<AiEngineNumber>({
    SD: 20,
    SDXL: 20,
    Flux11Pro: 28,
    Flux1Dev: 28,
    Flux1Schnell: 4,
    Flux1RealismLoRA: 28,
    FluxJuno: 28,
  });
  const [sample, setSample] = useState<AiEngineNumber>({
    DallE3: 1,
    SD: 1,
    SDXL: 1,
    SD3: 1,
    SDUltra: 1,
    Flux11Pro: 1,
    Flux1Dev: 1,
    Flux1Schnell: 1,
    Flux1RealismLoRA: 1,
    FluxJuno: 1,
  });
  const [seed, setSeed] = useState<AiEngineNumber>({
    SD: 0,
    SDXL: 0,
    SD3: 0,
    SDUltra: 0,
    Flux11Pro: 0,
    Flux1Dev: 0,
    Flux1Schnell: 0,
    Flux1RealismLoRA: 0,
    FluxJuno: 0,
    Ideogram2: 0,
    Ideogram2Turbo: 0,
  });
  const [isPublic, setIsPublic] = useState<boolean>(true);

  const setWidthPartial = (key: AiEngineList, value: number) => {
    setWidth((prev) => ({ ...prev, [key]: value }));
  };
  const setHeightPartial = (key: AiEngineList, value: number) => {
    setHeight((prev) => ({ ...prev, [key]: value }));
  };

  return (
    <ParamsContext.Provider
      value={{
        prompt,
        setPrompt,
        negativePrompt,
        setNegativePrompt,
        revisedPrompt,
        setRevisedPrompt,
        style,
        setStyle,
        styleWeight,
        setStyleWeight,
        modelSD,
        setModelSD,
        modelSDXL,
        setModelSDXL,
        loraSD,
        setLoraSD,
        width,
        setWidthPartial,
        height,
        setHeightPartial,
        aspectRatio,
        setAspectRatio,
        guidanceScale,
        setGuidanceScale,
        step,
        setStep,
        sample,
        setSample,
        seed,
        setSeed,
        isPublic,
        setIsPublic,
      }}
    >
      {children}
    </ParamsContext.Provider>
  );
};

export const useParamsContext = (): ParamsContextType => {
  const context = useContext(ParamsContext);
  if (!context) {
    throw new Error("useParamsContext must be used within a ParamsProvider");
  }
  return context;
};
