import { Fade, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import ParamsDallE from "./ParamsDallE";
import ParamsFlux from "./ParamsFlux";
import ParamsSD from "./ParamsSD";
import ParamsSD3 from "./ParamsSD3";
import ParamsUpscale from "./ParamsUpscale";
import ParamsIdeogram from "./ParamsIdeogram";

export default function InputOption() {
  const params = new URLSearchParams(window.location.search);
  const ai_engine = params.get("ai_engine");
  const theme = useTheme();

  return (
    <Paper sx={{ height: "100%", overflowY: "auto" }} elevation={theme.palette.mode === "dark" ? 1 : 0}>
      {
        // ai_engineがAiEngineListに含まれているかどうかで条件分岐
        ai_engine ? (
          <Fade in={true}>
            <Box
              sx={{
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
                width: "100%",
                p: 2,
                whiteSpace: "nowrap",
              }}
            >
              {/* DALL-E 3*/}
              {ai_engine === "DallE3" && <ParamsDallE />}
              {/* FLUX */}
              {ai_engine.includes("Flux") && <ParamsFlux />}
              {/* Ideogram */}
              {["Ideogram2", "Ideogram2Turbo"].includes(ai_engine) && <ParamsIdeogram />}
              {/* SD, SDXL */}
              {["SD", "SDXL"].includes(ai_engine) && <ParamsSD />}
              {/* SD3, SDUltra */}
              {["SD3", "SDUltra"].includes(ai_engine) && <ParamsSD3 />}
              {/* Upscale */}
              {["AuraSR", "ClarityUpscaler", "CreativeUpscaler"].includes(ai_engine) && <ParamsUpscale />}
              <Box flexGrow={1} />
            </Box>
          </Fade>
        ) : (
          <Box
            sx={{
              justifyContent: "center",
              display: "flex",
              width: "100%",
              height: "100%",
              alignItems: "center",
            }}
          >
            <Box>
              <CircularProgress />
            </Box>
          </Box>
        )
      }
    </Paper>
  );
}
