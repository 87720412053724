import React from "react";
import { Fade } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { useParams } from "react-router-dom";
import { useCustomNavigate } from "../../../../hooks/useCustomNavigate";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { useFileSidebarContext } from "../../../../context/FileSidebarContext";
import CircularProgress from "@mui/material/CircularProgress";
import { streamStop } from "../../../../utils/stream_stop";
import Button from "@mui/material/Button";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { setSaveTrigger } from "../../../../redux/slices/fileSlice";
import { GradationButton } from "../../../../utils/gradationButton";
import { RowCenteredBox } from "../../../../utils/styledBox";
import Cookies from "js-cookie";
import { setCreditTrigger } from "../../../../redux/slices/triggerSlice";
import { useAlert } from "../../../../context/AlertContext";
import { useCheckCredit } from "../../../../hooks/useCreditCheck";
import ProPlanModal from "../../../../components/common/ProPlanModal";

export default function GenerateButton({ handleCreate }: { handleCreate: () => void }) {
  const { t } = useTranslation();
  const { fileUuid } = useParams();
  const selection = useSelector((state: RootState) => state.file.selection)[fileUuid!] || { text: "" };
  const context = useFileSidebarContext();
  const navigate = useCustomNavigate();
  const dispatch = useDispatch();
  const { setAlert } = useAlert();
  const { checkCredit } = useCheckCredit();
  const [openProDialog, setOpenProDialog] = React.useState(false);
  const [dialogMessage, setDialogMessage] = React.useState("");
  const [generatingImage, setGeneratingImage] = React.useState(false);

  const handleNavigate = () => {
    dispatch(setSaveTrigger(true));
    navigate("/voice-generator", { state: { textEditor: selection.text } });
  };
  const label = () => {
    return context.selectedButton === "createImages"
      ? t("textEditor.sidebar.generateImage")
      : t("textEditor.sidebar.generate");
  };

  // 画像生成APIへのリクエスト
  const handleGenerateImage = async (request_uuid?: string | null) => {
    setGeneratingImage(true);
    try {
      if (!(await checkCredit())) return;
      setGeneratingImage(true);
      if (context.originalText === "") {
        setAlert("error", "Please input text.");
        return;
      }

      const url = "/api/v1/juno/generate";
      const csrftoken = Cookies.get("csrftoken");
      const headers = new Headers({
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken!,
      });

      // data整形
      const default_data = {
        action: "txt2img",
        prompt: context.originalText,
        aiEngine: context.aiEngine,
        requestUuid: request_uuid,
        isPublic: true,
        needFixPrompt: true,
      };
      let data;

      switch (context.aiEngine) {
        case "DallE3":
          data = {
            ...default_data,
            width: 1024,
            height: 1792,
            revisedPrompt: true,
            style: "vivid",
          };
          break;
        case "SD3":
        //   SDUltraと同じ
        // eslint-disable-next-line no-fallthrough
        case "SDUltra":
          data = {
            ...default_data,
            negativePrompt: "",
            aspectRatio: "3:2",
            seed: 0,
          };
          break;
        case "Flux1Schnell":
          data = {
            ...default_data,
            aspectRatio: "4:3",
            seed: 0,
            guidanceScale: 3.5,
            step: 4,
            sample: 1,
          };
          break;
        // eslint-disable-next-line no-fallthrough
        case "Flux11Pro":
        // Flux1Devと同じ
        // eslint-disable-next-line no-fallthrough
        case "Flux1RealismLoRA":
        // Flux1Devと同じ
        // eslint-disable-next-line no-fallthrough
        case "Flux1Dev":
          data = {
            ...default_data,
            aspectRatio: "4:3",
            seed: 0,
            guidanceScale: 3.5,
            step: 28,
            sample: 1,
          };
          break;
        case "Ideogram2":
        // Ideogram2Turboと同じ
        // eslint-disable-next-line no-fallthrough
        case "Ideogram2Turbo":
          data = {
            ...default_data,
            aspectRatio: "3:2",
            seed: 0,
            style: "GENERAL",
            revisedPrompt: "AUTO",
          };
          break;
        default:
          data = { ...default_data, width: 1024, height: 1024 };
      }

      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({ data }),
      });
      await response.json().then((res) => {
        if (res.status === "success") {
          context.setGeneratedImageURL(res.url);
        } else if (res.status === "temporary_success") {
          handleGenerateImage(res.request_uuid);
        } else {
          if (res.message === "NO_CREDITS") {
            setDialogMessage(t("juno.error.noCredits"));
            setOpenProDialog(true);
          } else if (res.message === "UPGRADE_PLAN") {
            setDialogMessage(t("juno.error.upgrade"));
            setOpenProDialog(true);
          } else {
            setAlert("error", res.message);
          }
        }
      });
    } catch (e) {
      setAlert("error", "Something went wrong. Please try again.");
    } finally {
      dispatch(setCreditTrigger(true));
      setGeneratingImage(false);
    }
  };

  return (
    // Generate Button
    <>
      <RowCenteredBox>
        {context.selectedButton === "createVoice" ? (
          <Button disableElevation variant={"outlined"} sx={{ minWidth: "211px", py: 1 }} onClick={handleNavigate}>
            {t("textEditor.sidebar.createVoiceButton")}
            <ExitToAppIcon fontSize={"small"} sx={{ ml: 1 }} />
          </Button>
        ) : context.selectedButton === "createImages" ? (
          <GradationButton
            onClick={() => {
              if (!generatingImage) {
                handleGenerateImage();
              }
            }}
            sx={{ minWidth: "211px", py: 1 }}
            disabled={generatingImage}
          >
            {generatingImage ? (
              <Fade in={generatingImage} timeout={500}>
                <RowCenteredBox>
                  <CircularProgress size={20} sx={{ mr: 1, ml: -2, color: "white" }} />
                  <Typography variant={"button"}>{t("common.generating")}</Typography>
                </RowCenteredBox>
              </Fade>
            ) : (
              <Fade in={!generatingImage} timeout={500}>
                <RowCenteredBox>
                  <KeyboardDoubleArrowDownIcon fontSize={"small"} sx={{ mr: 1 }} />
                  <Typography variant={"button"}>{t("textEditor.sidebar.generateImage")}</Typography>
                </RowCenteredBox>
              </Fade>
            )}
          </GradationButton>
        ) : (
          <GradationButton
            onClick={() => {
              if (context.processing) {
                streamStop(fileUuid!);
              } else {
                handleCreate();
              }
            }}
            sx={{ minWidth: "211px", py: 1 }}
          >
            {context.processing ? (
              <Fade in={context.processing} timeout={500}>
                <RowCenteredBox>
                  <CircularProgress size={20} sx={{ mr: 1, ml: -2, color: "white" }} />
                  <Typography variant={"button"}>{t("browsing.stopGenerating")}</Typography>
                </RowCenteredBox>
              </Fade>
            ) : (
              <Fade in={!context.processing} timeout={500}>
                <RowCenteredBox>
                  <KeyboardDoubleArrowDownIcon fontSize={"small"} sx={{ mr: 1 }} />
                  <Typography variant={"button"}>{label()}</Typography>
                </RowCenteredBox>
              </Fade>
            )}
          </GradationButton>
        )}
      </RowCenteredBox>
      <ProPlanModal open={openProDialog} setOpen={setOpenProDialog} message={dialogMessage} />
    </>
  );
}
