// JunoImageProvider.tsx
import React from "react";
import { ParamsProvider } from "./ParamsContext";
import { UpscaleProvider } from "./UpscaleContext";
import { UploadImageProvider } from "./UploadImageContext";
import { CommonProvider } from "./CommonContext";

export const JunoImageProvider: React.FunctionComponent<{ children?: React.ReactNode }> = ({ children }) => {
  return (
    <ParamsProvider>
      <UpscaleProvider>
        <UploadImageProvider>
          <CommonProvider>{children}</CommonProvider>
        </UploadImageProvider>
      </UpscaleProvider>
    </ParamsProvider>
  );
};
